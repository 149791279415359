import {Component, Input} from '@angular/core';
import {
    BaseMeasurementChartComponent,
    ChartDatasetExtended,
    MeasurementData,
    MeasurementDataset
} from "../base-measurement-chart";
import {ConnectionType, DataType, HourValue} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardContext} from "../../dashboard/dashboard.context";
import {ChartOptions, ChartOptionType} from "../../dashboard/dashboard.types";
import {AppContext} from "../../../app-context";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import {lodash, removeItem} from "../../../common/utils";
import {TranslateDirective} from "../../../common/translate.directive";
import {SentenceCasePipe} from "../../../common/sentence-case.pipe";
import {ActiveElement, Chart, ChartEvent} from "chart.js";
import {cloneDeep} from "lodash";

@Component({
    selector: 'app-day-of-week-chart',
    templateUrl: './day-of-week-chart.component.html',
    styleUrls: ['./day-of-week-chart.component.scss']
})
export class DayOfWeekChart extends BaseMeasurementChartComponent {

    dashboardContext = DashboardContext;
    _rawDataset: ChartDataPerMeasurement;
    records: DayOfWeekChartRecord[] = [];
    estimatedRecords: DayOfWeekChartRecord[] = [];

    colors = {
        "MONDAY": "rgb(239,40,40)",
        "TUESDAY": "rgb(246,170,55)",
        "WEDNESDAY": "rgb(201,248,80)",
        "THURSDAY": "rgb(87,232,129)",
        "FRIDAY": "rgb(106,204,246)",
        "SATURDAY": "rgb(144,105,243)",
        "SUNDAY": "rgb(212,97,225)",
    }

    getDayOfWeekInputId = (dayOfWeek: string) => this.getInputId('checkbox-' + dayOfWeek + '-' + this.optionsType());

    isDayOfWeekSelected = (dayOfWeek: string) => this.options.selectedDays.includes(dayOfWeek);

    allDaysSelected = (): boolean => this.options.selectedDays.length === DashboardContext.weekdaysSorted.length;
    
    get options(): ChartOptions {
        const opts = super.options;
        opts.showAllDays = opts.showAllDays === undefined ? true : opts.showAllDays;
        opts.selectedDataType = opts.selectedDataType || DataType.electricityConsumption;
        opts.selectedDays = opts.selectedDays || [];
        return opts;
    }

    @Input()
    set allDaysByDefault(value : boolean) {
        this.toggleAllDays(value);
    }

    toggleAllDays = (selected: boolean) => {
        this.options.selectedDays = selected ? cloneDeep(DashboardContext.weekdaysSorted) : [];
        this.options.showAllDays = !this.options.selectedDays.length;
        this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
    }

    toggleDayOfWeek = (dayOfWeek: string) => {
        if (this.isDayOfWeekSelected(dayOfWeek)) {
            this.options.selectedDays = removeItem(this.options.selectedDays, dayOfWeek)
        } else {
            this.options.selectedDays.push(dayOfWeek);
        }
        this.options.showAllDays = !this.options.selectedDays.length;
        this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
    }

    get showAsLineChart(): boolean {
        return !this.options.showAllDays && (!this.options.selectedDays?.length || this.options.selectedDays.length > 1);
    }

    setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
        this._rawDataset = result;
        this._rawData = measurements;
        this._rawEstimatedData = estimatedMeasurements;
        this.createData(result);
        const value: MeasurementData = this.options.showAllDays ? this.getDatasetForAllDays() : {
            labels: lodash.range(0, 24).map(h => `${h}:00`),
            datasets: this.records
                .filter(r => this.options.selectedDays.includes(r.dayOfWeek))
                .map(r => ({
                    entityId: null,
                    measurementType: this.options.selectedDataType,
                    dataset: this.getDataset(false, r)
                }))
        };
        this.chartDataProvider.emit(value);
    }

    createData = (result: ChartDataPerMeasurement) => {
        this.records = [];
        this.estimatedRecords = [];
        Object.entries(result.byDayOfWeek.measurements).forEach(([dayOfWeek, result]) => {
            const dataPerHour: {[hour: number]: HourValue} = {};
            Object.entries(result)
                .filter(([dataType]) => this.measurementTypes().includes(dataType as DataType))
                .forEach(([dataType, values]) => {
                    values.forEach(v => {
                        const val = dataPerHour[v.hour] || {hour: v.hour, value: 0};
                        val.value += v.value;
                        dataPerHour[v.hour] = val;
                    });
                });
            this.records.push({
                dayOfWeek: dayOfWeek,
                values: Object.values(dataPerHour)
            });
        });
        this.records = lodash.sortBy(this.records, r => DashboardContext.weekdaysSorted.indexOf(r.dayOfWeek));
    }

    private getDataset = (estimated: boolean, data: DayOfWeekChartRecord): ChartDatasetExtended => {
        const color = this.showAsLineChart ? this.colors[data.dayOfWeek] : DashboardContext.getMeasurementColor(DashboardContext.stacks.currentPeriod, this.options.selectedDataType);
        const label = TranslateDirective.getTranslation(SentenceCasePipe.format(data.dayOfWeek), true);
        return {
            type: this.showAsLineChart ? "line" : null,
            yAxisID: "lines",
            label: estimated ? `${label} (${TranslateDirective.getTranslation("estimated", true)})` : label,
            data: data.values.map(v => v.value),
            order: 1,
            borderColor: color,
            backgroundColor: estimated ? "#FFFFFF" : color,
            pointBorderColor: color,
            measurementType: this.options.selectedDataType,
            stack: data.dayOfWeek,
            tooltip: {
                formatter: this.chartUtils.getCustomTooltipFormatter(this.options.selectedDataType, false),
                labelOverride: this.measurementName(this.options.selectedDataType),
                data: data.values.map(v => v.value),
                stack: DashboardContext.stacks.currentPeriod,
                estimated: estimated
            }
        }
    }

    measurementTypes = (): DataType[] => {
        if (this.options.selectedDataType === DataType.electricityConsumption) {
            return [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak];
        }
        if (this.options.selectedDataType === DataType.electricityFeedIn) {
            return [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak];
        }
        if (this.options.selectedDataType === DataType.electricityConsumptionReactive) {
            return [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak,
                DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak];
        }
        if (this.options.selectedDataType === DataType.electricityPower) {
            return [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak];
        }
        return [this.options.selectedDataType];
    };

    productionDataTypes = (): DataType[] => [];

    powerDataType = (): DataType => null;

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    connectionType = (): ConnectionType => null;

    openModal = () => this.openModalWithType(DayOfWeekChart);

    measurementUnit = (): string => `${DashboardContext.getMeasurementUnit(this.options.selectedDataType)}`;

    optionsType = (): ChartOptionType => ChartOptionType.DaysOfWeek;

    measurementName = (measurementType: DataType, translate: boolean = true): string => {
        return AppContext.entityPerformanceMeasurementName(measurementType, translate);
    }

    dropdownFormatter = (measurementType: DataType) => this.measurementName(measurementType, false);

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityConsumption, DataType.electricityFeedIn, DataType.electricityConsumptionReactive, DataType.electricityGrossProduction, DataType.electricityPower]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption])
            .concat(AppContext.isAdmin() ? [DataType.gasConsumptionUncorrected] : [])
            .concat([DataType.electricityConsumptionCosts, DataType.gasConsumptionCosts, DataType.waterConsumptionCosts,
                DataType.heatConsumptionCosts])
            .concat([DataType.co2EmissionFromElectricity, DataType.co2EmissionFromGas, DataType.co2EmissionFromWater,
                DataType.co2EmissionFromHeat])
            .concat(AppContext.weatherTypes);

    measurementTypeChanged = (m: DataType) => {
        this.options.selectedDataType = m;
        this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
    }

    private getDatasetForAllDays(): MeasurementData {
        const measurementColor = DashboardContext.getMeasurementColor(DashboardContext.stacks.currentPeriod, this.options.selectedDataType);
        const aggregationMethod = this.options.selectedDataType === DataType.electricityPower
            ? lodash.max : AppContext.isWeatherType(this.options.selectedDataType)
                ? lodash.mean : lodash.sum;
        const records = this.records.map(r => aggregationMethod(r.values.map(v => v.value)));
        const labels = this.records
            .map(r => SentenceCasePipe.format(TranslateDirective.getTranslation(r.dayOfWeek, true)));
        return {
            labels: labels.map(l => l.substring(0, 2)),
            datasets: [{
                measurementType: this.options.selectedDataType,
                estimated: false,
                dataset: {
                    yAxisID: "lines",
                    label: this.measurementName(this.options.selectedDataType),
                    data: records,
                    order: 1,
                    borderColor: measurementColor,
                    backgroundColor: measurementColor,
                    pointBorderColor: measurementColor,
                    measurementType: this.options.selectedDataType,
                    stack: DashboardContext.stacks.currentPeriod,
                    tooltipLabels: labels,
                    tooltip: {
                        formatter: this.chartUtils.getCustomTooltipFormatter(this.options.selectedDataType, false),
                        labelOverride: this.measurementName(this.options.selectedDataType),
                        data: records,
                        estimated: false
                    }
                }
            }]
        }
    }

    navigateToDay = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const points = chart.getElementsAtEventForMode(event.native, 'nearest', {intersect: true}, true);
        if (points && elements.length > 0) {
            if (this.options.showAllDays) {
                const clickedRecord: DayOfWeekChartRecord = this.records[points[0].index];
                this.options.selectedDays = [clickedRecord.dayOfWeek];
                this.options.showAllDays = false;
                this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
                this.changeDetectorRef.detectChanges();
            }
        } else {
            if (!this.options.showAllDays) {
                this.options.showAllDays = true;
                this.options.selectedDays = [];
                this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    onHover = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const target: any = event.native.target;
        if (this.options.showAllDays) {
            target.style.cursor = elements.length > 0 ? "pointer" : "auto";
        } else {
            target.style.cursor = elements.length > 0 ? "auto" : "zoom-out";
        }
    }
}

interface DayOfWeekChartRecord {
    dayOfWeek: string;
    values: HourValue[];
}