import {Component, Input, OnInit} from '@angular/core';
import {
    Connection,
    ConnectionInfo,
    ElectricityConnectionInfo,
    GasConnectionInfo,
    HeatConnectionInfo,
    UpsertConnection,
    UpsertEnecoMeter,
} from "@flowmaps/flowmaps-typescriptmodels";
import {publishEvent, sendCommand} from "../../../../flux/flux-utils";
import {closeModal} from "../../../../app-utils";
import {AppContext} from "../../../../app-context";
import {map, Observable} from "rxjs";
import {filterValuesByTerm, uuid} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {QueryGateway} from "../../../../flux/query-gateway";
import {ExtendedLocation} from "../../locations/locations-list/locations-list.component";

@Component({
    selector: 'app-connection-details',
    templateUrl: './connection-details.component.html',
    styleUrls: ['./connection-details.component.scss']
})
export class ConnectionDetailsComponent implements OnInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    @Input() data: ConnectionComponentData;

    newConnectionId: string;
    connectionTypes: string[] = ["Electricity", "Gas", "Heat", "Water"];
    marketSegments: string[] = ["KV", "GV"];
    location: ExtendedLocation;
    info: ConnectionInfo;
    enecoMeterNumber: string;

    constructor(private queryGateway: QueryGateway) {
    }

    get infoAsElectricity(): ElectricityConnectionInfo {
        return this.info as ElectricityConnectionInfo;
    }

    get infoAsGas(): GasConnectionInfo {
        return this.info as GasConnectionInfo;
    }

    get infoAsHeat(): HeatConnectionInfo {
        return this.info as HeatConnectionInfo;
    }

    get isEnecoConnection(): boolean {
        return this.info.surveyor?.code === "089349d8-db89-447e-a838-79a0794997cf";
    }

    get contractedCapacity() {
        return this.info.connectionValue?.contractedCapacity || this.info.contractedCapacity;
    }

    ngOnInit(): void {
        if (!this.data.connection) {
            this.newConnectionId = uuid();
        }
        this.info = this.data.connection?.info || <ConnectionInfo>{};
        if (this.isEnecoConnection) {
            this.enecoMeterNumber = this.data.connection?.meters?.find(m => m.meterId.startsWith("eneco-"))?.info?.meterNumbers[0];
        }
        if (this.data.organisationId) {
            RefdataUtils.getLocations(this.data.organisationId).subscribe(l => this.location = l.find(l => l.locationId === this.data?.locationId));
        }
    }

    onLocationSelected = (location: ExtendedLocation) => {
        this.data.organisationId = location.organisationId;
    }

    searchLocation = (term: string): Observable<ExtendedLocation[]> => (this.data.organisationId ? RefdataUtils.getLocations(this.data.organisationId) : RefdataUtils.getAllLocations())
        .pipe(map(locs => filterValuesByTerm(locs, term)))
        .pipe(map(values => values.sort(RefdataUtils.locationsComparator.compare)));

    updateInfo = () => {
        if (this.isEnecoConnection) {
            const command: UpsertEnecoMeter = {
                organisationId: this.data.organisationId,
                locationId: this.location.locationId,
                connectionId: this.newConnectionId || this.data.connection?.connectionId,
                info: this.infoAsHeat,
                meterNumber: this.enecoMeterNumber
            };
            return sendCommand("com.flowmaps.api.organisation.UpsertEnecoMeter", command, () => {
                closeModal();
                publishEvent('reloadOverview')
            });
        } else {
            const command: UpsertConnection = {
                organisationId: this.data.organisationId,
                locationId: this.location.locationId,
                connectionId: this.newConnectionId || this.data.connection?.connectionId,
                info: this.info
            };
            return sendCommand("com.flowmaps.api.organisation.UpsertConnection", command, () => {
                closeModal();
                this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
                publishEvent(this.newConnectionId ? "recordAdded" : "recordUpdated", command);
            });
        }
    };
}

export interface ConnectionComponentData {
    organisationId: string;
    locationId: string;
    connection: Connection;
}