import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import html2canvas from 'html2canvas';
import {AppContext} from "../../../app-context";
import {exportTableToExcel} from "../../../common/upload/excel.utils";
import {ConnectionType, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {
  connectionCsvDataToJson,
  downloadRawConnectionData
} from "../../refdata/connections/connections-list/connections-list.component";
import {MeasurementsDataProvider} from "../../../utils/measurements-data-provider";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {SourceInfo} from "../../../utils/source-providers/sources-provider";
import {TranslateDirective} from "../../../common/translate.directive";

@Component({
  selector: 'app-chart-download-button',
  templateUrl: './chart-download-button.component.html',
  styleUrls: ['./chart-download-button.component.css']
})
export class ChartDownloadButton implements OnInit {
  appContext = AppContext;
  @Input() element : HTMLElement | ElementRef;
  @Input() tableElement: ElementRef;
  @Input() fileName : string;

  selectedConnections: SourceInfo[] = [];
  rawDataDownloadThreshold: number = 5;
  @Input() connectionTypes: ConnectionType[] | string[];
  @Input() chartProvider: MeasurementsDataProvider<any>;

  ngOnInit() {
    this.chartProvider.subscribeToSourceUpdates((sources) =>
        this.selectedConnections = sources.filter(s => (this.connectionTypes || []).includes(s.connectionType)))
  }

  downloadImage() {
    const subject = new Subject<void>();
    const process = AppContext.waitForProcess(subject);
    process.subscribe();
    const element : HTMLElement = this.element ? this.element['nativeElement'] || this.element : document.body;
    html2canvas(element).then(canvas => {
      try {
        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('download', this.fileName || 'screenshot.png');
        canvas.toBlob(function(blob) {
          let url = URL.createObjectURL(blob);
          downloadLink.setAttribute('href', url);
          downloadLink.click();
        });
      } finally {
        subject.next();
      }
    });
  }

  get rawDataExportSupported(): boolean {
    return (this.connectionTypes || []).filter(a => a).length > 0;
  }

  get toManyConnectionsTooltip(): string {
    return `${TranslateDirective.getTranslation('Data download is only possible below a selection of')} ${this.rawDataDownloadThreshold + 1} ${TranslateDirective.getTranslation('connections').toLowerCase()}`;
  }

  get duration() {
    return AppContext.durationForTimeRange(this.chartProvider.info.timeRange);
  }

  downloadTable = () => exportTableToExcel(this.tableElement);

  belowRawDataThreshold = () => this.selectedConnections.length && this.selectedConnections.length <= this.rawDataDownloadThreshold;

  downloadRawData = (resolution: TimeResolution | string) => downloadRawConnectionData(this.chartProvider.info.timeRange, resolution as TimeResolution,
      this.selectedConnections.map(c => (<any>{
        connection: c.source
      })), connectionCsvDataToJson, RefdataUtils.connectionsComparator);
}
