<div #container class="d-flex align-items-stretch" style="flex: 1" [ngClass]="{'mb-0': _dataOptions.fullScreen || showInReport, 'card': !showInReport, 'report-view': showInReport}">
    <ng-container *ngIf="!showInReport">
        <div class="card-header dropdown">
            <h4 class="card-header-title">
                <app-connection-image [connectionType]="'Gas'"></app-connection-image>
                Gas and heat consumption
                <app-tooltip class="ms-2" placement="top" *ngIf="completenessInfo">
                    <span class="notranslate" [style.color]="completenessInfo.color">{{completenessInfo.completeness}}%</span>
                    <div class="tooltipContent">
                        <span class="notranslate">{{completenessInfo.title}}</span>
                    </div>
                </app-tooltip>
            </h4>
            <app-data-provider-date-range class="ms-2" *ngIf="_dataOptions.fullScreen" [dataProvider]="_dataOptions.dataProvider"></app-data-provider-date-range>
            <button *ngIf="!_dataOptions.fullScreen" type="button" class="btn" role="button" (click)="openModal()"><i class="bi bi-arrows-angle-expand"></i></button>
            <app-chart-download-button [element]="container" [tableElement]="tableElement"
                                       [chartProvider]="_dataOptions.dataProvider" [connectionTypes]="['Gas', 'Heat']"></app-chart-download-button>
            <button type="button" class="btn" role="button" (click)="toggleShowAsTable()"><i class="bi" [ngClass]="options.showAsTable ? 'bi-bar-chart-line' : 'bi-table'"></i></button>
            <button type="button" class="btn" role="button" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <div class="dropdown-menu" style="z-index: 1050;">
                <div class="dropdown-item" *ngIf="_dataOptions.dataProvider.groupByEntityIdEnabled()">
                    <div class="form-check">
                        <ng-container *ngIf="options.groupByEntityIdPossible else disabledGroupByEntityId">
                            <input class="form-check-input" type="checkbox" value="" [id]="getInputId('groupByEntityId')"
                                   [ngModel]="options.groupByEntityId" (ngModelChange)="groupByEntityIdChange($event)">
                            <label class="form-check-label" [for]="getInputId('groupByEntityId')">
                                Split by sub meters
                            </label>
                        </ng-container>
                        <ng-template #disabledGroupByEntityId>
                            <input class="form-check-input" type="checkbox" value="false" disabled>
                            <label class="form-check-label me-2">Split by sub meters</label>
                            <i class="bi bi-exclamation-triangle-fill" data-bs-toggle="tooltip" data-bs-placement="top"
                               title="No sub-meters found for this measurement type"></i>
                        </ng-template>
                    </div>
                </div>
                <div class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('showCompleteness')"
                               [ngModel]="options.showCompleteness" (ngModelChange)="showCompletenessChange($event)">
                        <label class="form-check-label" [for]="getInputId('showCompleteness')">
                            Completeness
                        </label>
                    </div>
                </div>
                <div class="dropdown-item" *ngIf="appContext.isAdmin()">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('showUncorrectedConsumption')"
                               [ngModel]="options.showUncorrectedConsumption" (ngModelChange)="showUncorrectedConsumptionChange($event)">
                        <label class="form-check-label" [for]="getInputId('showUncorrectedConsumption')">
                            Show uncorrected gas consumption
                        </label>
                    </div>
                </div>
                <div class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('checkboxCompareGas')"
                               [ngModel]="options.compare.enabled" (ngModelChange)="compareChange($event)">
                        <div class="row">
                            <label class="form-check-label col-auto" [for]="getInputId('checkboxCompareGas')">Compare with</label>
                            <app-select class="col px-0 notranslate" [ngModel]="options.compare" (ngModelChange)="comparedYearChange($event)"
                                        [options]="compareYearOptions" [small]="true" [formatter]="compareFormatter" [equalsFunction]="compareEquals"></app-select>
                        </div>
                    </div>
                </div>
                <div class="dropdown-item">
                    <ng-container *ngIf="_dataOptions.dataProvider.isPortfolioProvider() else locationDashboardPower">
                        <ng-container *ngTemplateOutlet="portfolioDashboardPower"></ng-container>
                    </ng-container>
                </div>
                <hr />
                <div><h4 class="dropdown-header">Weather</h4></div>
                <div *ngFor="let weatherType of weatherTypes" class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getWeatherInputId(weatherType)"
                               [checked]="isDataTypeSelected(weatherType)" (click)="toggleWeather(weatherType)">
                        <label class="form-check-label" [for]="getWeatherInputId(weatherType)">
                            {{appContext.measurementName(weatherType, false)}}
                        </label>
                    </div>
                </div>
            </div>
            <button *ngIf="_dataOptions.fullScreen" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
    </ng-container>
    <div class="chart-body pt-0" [ngClass]="{'p-0': options.showAsTable, 'card-body': !showInReport}">
        <ng-container *ngIf="showInReport else notInReport">
            <ng-container [ngTemplateOutlet]="chart"></ng-container>
            <app-chart-table class="w-100" [dataProvider]="chartDataProvider"
                             [measurementsProvider]="_dataOptions.dataProvider"
                             [compare]="options.compare"></app-chart-table>
        </ng-container>
        <ng-template #notInReport>
            <app-chart-table class="w-100" [dataProvider]="chartDataProvider" [ngClass]="{'d-none': !options.showAsTable}"
                             [measurementsProvider]="_dataOptions.dataProvider" [compare]="options.compare"
                             (tableElement)="tableElement = $event"></app-chart-table>
            <ng-container *ngIf="!options.showAsTable">
                <ng-container [ngTemplateOutlet]="chart"></ng-container>
            </ng-container>
        </ng-template>
    </div>
</div>

<ng-template #chart>
    <app-bar-chart class="chart h-100 w-100" [y1AxisMeasurement]="measurementUnit()"
                   style="flex: 1" [dataProvider]="chartDataProvider" [showNormalAxis]="showDataNormally()"
                   [y2AxisMeasurement]="y2AxisTitle" [powerAxisMeasurement]="powerAxisTitle" [measurementsProvider]="_dataOptions.dataProvider"
                   [selectedWeatherTypes]="options.selectedWeatherTypes" [weatherTypes]="weatherTypes" [showCompleteness]="options.showCompleteness"
                   [fullScreen]="_dataOptions.fullScreen" [skipPaddingX]="_dataOptions.fullScreen || showInReport"
                   [animationDuration]="showInReport ? 0 : 1000"></app-bar-chart>
</ng-template>

<ng-template #portfolioDashboardPower>
    <ng-container *ngIf="{ val: hasMultipleConnectionsSelected()} as multipleConnections">
        <div class="form-check">
            <ng-container *ngIf="multipleConnections.val else enabled">
                <input class="form-check-input" type="checkbox" [id]="getInputId('checkboxShowPower')" disabled>
            </ng-container>
            <ng-template #enabled>
                <input class="form-check-input" type="checkbox" [id]="getInputId('checkboxShowPower')"
                       [ngModel]="options.showPower" (ngModelChange)="showPowerChange($event)">
            </ng-template>
            <label class="form-check-label" [for]="getInputId('checkboxShowPower')">
                <span class="me-2">Maximum power</span>
                <ng-container *ngIf="multipleConnections.val else oneConnectionSelected">
                    <i class="bi bi-exclamation-triangle-fill" data-bs-toggle="tooltip" data-bs-placement="top"
                       title="Maximum power can only be shown per EAN"></i>
                </ng-container>
                <ng-template #oneConnectionSelected>
                    <i *ngIf="!hasPower() else contractedCapacityEl" class="bi bi-exclamation-triangle-fill" data-bs-toggle="tooltip"
                       data-bs-placement="top" title="Power data is unavailable for this connection"></i>
                    <ng-template #contractedCapacityEl>
                        <i *ngIf="!contractedCapacity(connectionType())" class="bi bi-exclamation-triangle-fill" data-bs-toggle="tooltip"
                           data-bs-placement="top" title="Contracted power is unknown for this connection"></i>
                    </ng-template>
                </ng-template>
            </label>
        </div>
    </ng-container>
</ng-template>
<ng-template #locationDashboardPower>
    <ng-container *ngIf="{ val: getSelectedEans()} as electricityEans">
        <div class="form-check">
            <ng-container *ngIf="electricityEans.val.length === 0 else enabled">
                <input class="form-check-input" type="checkbox" [id]="getInputId('checkboxShowPower')" disabled>
            </ng-container>
            <ng-template #enabled>
                <input class="form-check-input" type="checkbox" [id]="getInputId('checkboxShowPower')"
                       [ngModel]="options.showPower && showPowerForEan(connectionType())"
                       (ngModelChange)="showPowerChange($event)">
            </ng-template>
            <div class="row">
                <label class="form-check-label col-auto" [for]="getInputId('checkboxShowPower')">Maximum power</label>
                <ng-container *ngIf="electricityEans.val.length > 1 else noElectricityConnections">
                    <app-select class="col px-0 notranslate" [ngModel]="options.showPowerForEan" (ngModelChange)="showPowerForEanChange($event)"
                                [options]="electricityEans.val" [small]="true"></app-select>
                </ng-container>
                <ng-template #noElectricityConnections>
                    <i *ngIf="electricityEans.val.length === 0" class="col-auto bi bi-exclamation-triangle-fill"
                       data-bs-toggle="tooltip" data-bs-placement="top" title="No connections found within selection"></i>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-template>