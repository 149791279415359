<div #container class="d-flex align-items-stretch" style="flex: 1" [ngClass]="{'mb-0': _dataOptions.fullScreen || showInReport, 'card': !showInReport, 'report-view': showInReport}">
    <ng-container *ngIf="!showInReport">
        <div class="card-header dropdown">
            <h4 class="card-header-title">
                <img src="assets/images/icons/7-days.png" width="20px" class="me-2">
                <span *ngIf="options.selectedDays.length !== 1">Days of week</span>
                <span *ngIf="options.selectedDays.length === 1">{{options.selectedDays[0]}}</span>
                <span class="mx-2 notranslate">–</span>
                <span class="notranslate">{{measurementName(options.selectedDataType, true)}}</span>
            </h4>
            <app-data-provider-date-range class="ms-2" *ngIf="_dataOptions.fullScreen" [dataProvider]="_dataOptions.dataProvider"></app-data-provider-date-range>
            <button *ngIf="!_dataOptions.fullScreen" type="button" class="btn" role="button" (click)="openModal()">
                <i class="bi bi-arrows-angle-expand"></i>
            </button>
            <app-chart-download-button [element]="container" [tableElement]="tableElement"
                                       [chartProvider]="_dataOptions.dataProvider" [connectionTypes]="[connectionType()]"></app-chart-download-button>
            <button type="button" class="btn" role="button" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <div class="dropdown-menu" style="z-index: 1050;">
                <div class="dropdown-item">
                    <div class="row">
                        <label class="form-check-label col-auto" [for]="getInputId('measurementType')+'-select'">Measurement</label>
                        <app-select class="col px-0" [ngModel]="options.selectedDataType" (ngModelChange)="measurementTypeChanged($event)" [id]="getInputId('measurementType')"
                                    [options]="possibleDataTypes()" [small]="true" [formatter]="dropdownFormatter"></app-select>
                    </div>
                </div>
                <hr />
                <div><h4 class="dropdown-header">Days of week</h4></div>
                <div class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('days-of-week-all-select')"
                               [ngModel]="allDaysSelected()" (ngModelChange)="toggleAllDays($event)">
                        <label class="form-check-label" [for]="getInputId('days-of-week-all-select')">
                            Toggle all days
                        </label>
                    </div>
                </div>
                <div *ngFor="let dayOfWeek of dashboardContext.weekdaysSorted" class="dropdown-item">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getDayOfWeekInputId(dayOfWeek)"
                               [checked]="isDayOfWeekSelected(dayOfWeek)" (click)="toggleDayOfWeek(dayOfWeek)">
                        <label class="form-check-label" [for]="getDayOfWeekInputId(dayOfWeek)">
                            {{dayOfWeek}}
                        </label>
                    </div>
                </div>
            </div>
            <button *ngIf="_dataOptions.fullScreen" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
    </ng-container>
    <div class="chart-body pt-0" [ngClass]="{'p-0': options.showAsTable, 'card-body': !showInReport}">
        <ng-container *ngIf="showInReport else notInReport">
            <ng-container [ngTemplateOutlet]="chart"></ng-container>
            <ng-container [ngTemplateOutlet]="tableView"></ng-container>
        </ng-container>
        <ng-template #notInReport>
            <ng-container *ngIf="options.showAsTable else showChart">
                <ng-container [ngTemplateOutlet]="tableView"></ng-container>
            </ng-container>
            <ng-template #showChart>
                <ng-container [ngTemplateOutlet]="chart"></ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>

<ng-template #chart>
    <app-bar-chart class="chart h-100 w-100" [stacked]="true" [y1AxisMeasurement]="measurementUnit()"
                   style="flex: 1" [measurementsProvider]="_dataOptions.dataProvider"
                   [y2AxisMeasurement]="y2AxisTitle" [showLegend]="showAsLineChart" [hideDefaultYAxis]="true"
                   [dataProvider]="chartDataProvider" [showNormalAxis]="showDataNormally()" [showCompleteness]="options.showCompleteness"
                   [selectedWeatherTypes]="options.selectedWeatherTypes" [weatherTypes]="weatherTypes"
                   [fullScreen]="_dataOptions.fullScreen" [skipPaddingX]="_dataOptions.fullScreen || showInReport"
                   [customOnClick]="navigateToDay" [customHover]="onHover" [animationDuration]="showInReport ? 0 : 1000">
    </app-bar-chart>
</ng-template>

<ng-template #tableView>
    <app-chart-table class="w-100" [dataProvider]="chartDataProvider"
                     [measurementsProvider]="_dataOptions.dataProvider"
                     [compare]="options.compare"></app-chart-table>
</ng-template>