<div class="main-content" *ngIf="contract">
    <div class="container-fluid min-vh-100 d-flex flex-column">
        <div class="row justify-content-between align-items-center">
            <div class="col-12">
                <div class="header">
                    <div class="header-body">
                        <div class="align-items-center row">
                            <div class="col">
                                <h6 class="header-pretitle">Contract</h6>
                                <h1 class="header-title text-truncate notranslate">{{ contract.contractData.name }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-tab-panel class="d-flex flex-column flex-grow-1">
            <div class="d-flex nav-bar-prefix me-3">
                <a class="nav-link" routerLink="/contracts" style="padding-top: 1rem;">
                    <i class="bi bi-arrow-left"></i>
                </a>
            </div>
            <app-tab-item [templateRef]="detailsRef">
                <a routerLink="details" class="tab-item text-reset text-decoration-none">
                    <span routerLink="details" class="tab-item">Details</span>
                </a>
                <ng-template #detailsRef>
                    <div class="tab-content">
                        <app-form (submit)="updateInfo()">
                            <app-contract-info [data]="{ contract: contract }" [readonly]="!appContext.isAdminOrIntermediary()"></app-contract-info>
                            <div *ngIf="appContext.isAdminOrIntermediary()" class="row mt-4">
                                <div class="col d-flex justify-content-between">
                                    <button type="button" class="btn btn-danger" (click)="deleteContract()" data-bs-dismiss="modal">
                                        Delete
                                    </button>
                                    <button type="submit" class="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </app-form>
                    </div>
                </ng-template>
            </app-tab-item>
            <app-tab-item [templateRef]="periodsRef">
                <a routerLink="periods" class="tab-item text-reset text-decoration-none">
                    <span routerLink="periods" class="tab-item">Contract Periods</span>
                </a>
                <ng-template #periodsRef>
                    <div class="tab-content">
                        <app-contract-periods-overview
                                [contract]="contract"></app-contract-periods-overview>
                    </div>
                </ng-template>
            </app-tab-item>
            <app-tab-item *ngIf="!contract.defaultContract" [templateRef]="linksRef">
                <a routerLink="links" class="tab-item text-reset text-decoration-none">
                    <span routerLink="links" class="tab-item">Links</span>
                </a>
                <ng-template #linksRef>
                    <div class="tab-content">
                        <app-linked-contracts-overview
                                [contract]="contract"></app-linked-contracts-overview>
                    </div>
                </ng-template>
            </app-tab-item>
        </app-tab-panel>
    </div>
</div>
