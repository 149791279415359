<div class="row">
    <div class="col-12 col-md-6">
        <app-form-field label="Name">
            <input [(ngModel)]="data.contract.contractData.name" type="text" class="form-control"
                   required [disabled]=readonly>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Product">
            <app-select [required]="true" [options]="connectionTypes"
                        [(ngModel)]="data.contract.contractData.connectionType"
                        [disabled]="readonly || !isNewRecord"></app-select>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Default Contract">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="defaultContractSwitch"
                       [(ngModel)]="data.contract.defaultContract" [disabled]="readonly || !isNewRecord" (change)="onDefaultContractChange()">
                <label class="form-check-label notranslate" for="defaultContractSwitch">
                    {{getToggleLabel()}}
                </label>
                <i class="bi bi-info-circle ms-3" data-bs-toggle="tooltip" data-bs-placement="top"
                   title="A default contract will use average consumption costs when no specific contracts are in place."></i>
            </div>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Contract Owner">
            <app-search [minCharacters]="0" [(ngModel)]="organisation" required [searchFunction]="searchOrganisation"
                        [inputFormatter]="refDataUtils.organisationFormatter"
                        (ngModelChange)="onOrganisationSelected($event)"
                        [disabled]="data.contract.defaultContract || readonly"
                        placeholder="Search for an organisation"></app-search>
        </app-form-field>
    </div>
</div>

