<app-form (submit)="updateInfo()">
    <div class="card">
        <div class="card-header">
            <h3 class="card-header-title">
                <app-connection-image [connectionType]="info.connectionType"></app-connection-image>
                Connection information</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-field label="Location">
                        <app-search [minCharacters]="0" [(ngModel)]="location" required [searchFunction]="searchLocation"
                                    [inputFormatter]="refDataUtils.locationsFormatter" dataKey="info.name" (ngModelChange)="onLocationSelected($event)"
                                    placeholder="Search for a location" ></app-search>
                    </app-form-field>
                </div>
                <ng-container *ngIf="location">
                    <div class="col-12 col-md-6">
                        <app-form-field label="Type">
                            <app-select required [(ngModel)]="info.connectionType" [options]="connectionTypes"
                                        [disabled]="!newConnectionId && !appContext.isAdmin()"></app-select>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Code">
                            <input required [(ngModel)]="info.code" type="text" class="form-control"
                                   [disabled]="!newConnectionId && !appContext.isAdmin()">
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Label">
                            <input [(ngModel)]="info.label" type="text" class="form-control">
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Market segment">
                            <app-select class="notranslate" [(ngModel)]="info.marketSegment" [options]="marketSegments"></app-select>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Supplier">
                            <app-service-provider-select class="notranslate" [type]="'supplier'" [(ngModel)]="info.supplier"></app-service-provider-select>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Operator">
                            <app-service-provider-select class="notranslate" [type]="'operator'" [(ngModel)]="info.operator"></app-service-provider-select>
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Supplier contract">
                            <input [(ngModel)]="info.supplierContract" type="text" class="form-control">
                        </app-form-field>
                    </div>
                    <div class="col-12 col-md-6">
                        <app-form-field label="Data source">
                            <app-service-provider-select class="notranslate" [required]="true" [type]="'surveyor'" [(ngModel)]="info.surveyor"></app-service-provider-select>
                        </app-form-field>
                    </div>
                    <ng-container *ngIf="isEnecoConnection">
                        <div class="col-12 col-md-6">
                            <app-form-field label="Customer id">
                                <input [(ngModel)]="infoAsHeat.customerId" type="text" class="form-control" required>
                            </app-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-form-field label="Meter number">
                                <input [(ngModel)]="enecoMeterNumber" type="text" class="form-control">
                            </app-form-field>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="info.marketSegment === 'KV'">
                        <div class="h-100"></div>
                        <div class="col-12 col-md-6" >
                            <app-form-field label="Start date">
                                <app-date-field [(ngModel)]="info.desiredStartDate"
                                                defaultTime="00:00:00" [hideTime]="true"></app-date-field>
                            </app-form-field>
                        </div>
                    </ng-container>
                    <div class="col-12 col-md-6">
                        <app-form-field label="End date">
                            <app-date-field [(ngModel)]="info.desiredEndDate" [dateOnly]="true" defaultTime="00:00:00" [atEndOfDay]="true"></app-date-field>
                        </app-form-field>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <ng-container *ngIf="info.connectionType">
                    <div class="col-12 col-md-6">
                        <ng-container *ngIf="info.marketSegment === 'KV' else gv">
                            <app-form-field label="Contracted capacity" [tooltip]="info.connectionValue ? null : 'Contracted capacity can be changed by selecting the correct connection value'">
                                <input [ngModel]="contractedCapacity" type="number" class="form-control" disabled>
                            </app-form-field>
                        </ng-container>
                        <ng-template #gv>
                            <app-form-field label="Contracted capacity">
                                <input [(ngModel)]="info.contractedCapacity" type="number" class="form-control">
                            </app-form-field>
                        </ng-template>
                    </div>
                </ng-container>
                <div class="col-12 col-md-6" *ngIf="info.connectionType === 'Electricity'">
                    <ng-container *ngIf="info.marketSegment === 'GV'">
                        <app-form-field label="Contracted feed-in capacity">
                            <input [(ngModel)]="infoAsElectricity.contractedFeedInCapacity" type="number" class="form-control">
                        </app-form-field>
                    </ng-container>
                </div>
            </div>

            <app-electricity-details *ngIf="info.connectionType === 'Electricity'" [info]="infoAsElectricity"></app-electricity-details>
            <app-gas-details *ngIf="info.connectionType === 'Gas'" [info]="infoAsGas"></app-gas-details>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-between">
                <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</app-form>