import {AfterViewInit, Component, Input} from '@angular/core';
import {RefdataUtils} from "../../refdata-utils";
import {ExtendedConnection} from "../connections-overview/connections-overview.component";
import {AbstractOverviewComponent} from "../../../../common/abstract-overview/abstract-overview.component";
import {AppContext} from "../../../../app-context";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {closeModal, openConfirmationModalWithCallback, openModal} from "../../../../app-utils";
import {ConnectionComponentData, ConnectionDetailsComponent} from "../connection-details/connection-details.component";
import {AnimationTypes} from "../../../../common/modal/modal.component";
import {
    ArrayTemplate,
    Cell,
    DateTimeField,
    downloadWorkbook,
    exportDataAsExcel,
    exportExcelFromWorkBook,
    Field,
    getWorkbook,
    parseExcel,
    Parser,
    QuantityField,
    RequiredField,
    ValidatedField,
    WorkBookTemplate
} from "../../../../common/upload/excel.utils";
import {
    AggregatedDataPoint,
    Connection,
    ConnectionInfo,
    DataType,
    DeleteConnection,
    DeleteMeter,
    ExportConnectionData,
    LinkedContract,
    Meter,
    TimeResolution,
    UnlinkContract,
    UploadMeterData,
    UpsertConnection,
    UpsertConnections,
    UpsertSolarEdgeMeter
} from "@flowmaps/flowmaps-typescriptmodels";
import {map, mergeMap, Observable} from "rxjs";
import * as XLSX from 'xlsx-js-style';
import {tap} from 'rxjs/operators';
import {publishEvent, sendCommand, sendQuery} from '../../../../flux/flux-utils';
import moment from 'moment';
import {downloadCsv} from '../../../../common/download.utils';
import lodash, {cloneDeep} from "lodash";
import {DashboardContext} from '../../../dashboard/dashboard.context';
import {SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";
import {DateFieldRange} from "../../../../common/date/date-range/date-field-range";
import {downloadBulkMonthData} from "./bulk-data-download";
import {MeterWithConnection} from "../../../location-dashboard/location-meters/location-meters.component";
import {
    UpsertSolarEdgeMeterComponent,
    UpsertSolarEdgeMeterComponentData
} from "../../meters/upsert-solar-edge-meter/upsert-solar-edge-meter.component";
import {downloadConnectionsAsExcel, uploadConnectionsExcel} from "./connections-list-excel";
import {EventGateway} from "../../../../flux/event-gateway";
import {QueryGateway} from "../../../../flux/query-gateway";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {
    LinkContractConnectionComponent,
    LinkContractToConnectionComponentData
} from "../link-contract-connection/link-contract-connection.component";
import {localTimeFormat} from "../../../../common/utils";

@Component({
    selector: 'app-connections-list',
    templateUrl: './connections-list.component.html',
    styleUrls: ['./connections-list.component.scss']
})
export class ConnectionsListComponent extends AbstractOverviewComponent<ExtendedConnection> implements AfterViewInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    dashboardContext = DashboardContext;
    @Input() intermediaryId: string;
    @Input() organisationId: string;

    comparator: ComparatorChain = RefdataUtils.connectionsComparator;
    downloadConnectionsAsExcel = downloadConnectionsAsExcel;
    downloadAsEdsnCsv = downloadAsEdsnCsv;
    connectionCsvDataToJson = connectionCsvDataToJson;
    connectionDataTemplate = connectionDataExportTemplate;

    defaultDownloadRange = DashboardContext.defaultRange;
    bulkDownloadTimeRange: DateFieldRange = this.defaultDownloadRange();
    sourceProvider: SourcesProvider<any>;
    bulkDownloading: boolean;
    maxSelectableItems: number = 10;

    constructor(protected eventGateway: EventGateway, protected queryGateway: QueryGateway) {
        super(eventGateway);
    }

    ngAfterViewInit(): void {
        this.sourceProvider = new OrganisationProvider(this.organisationId);

    }

    getContractDescription(contractId: string): Observable<string> {
        return RefdataUtils.getContract(contractId).pipe(map(c => c?.contractData.name));
    }

    meters = (connection: ExtendedConnection): MeterWithConnection[] =>
        connection.connection.meters.map(m => ({
            meter: m,
            connection: connection.connection,
            location: {
                locationId: connection.locationId,
                info: connection.locationInfo
            }
        }));

    linkedContracts = (connection: ExtendedConnection): LinkedContract[] => {
        if (connection.connection.linkedContracts) {
            return connection.connection.linkedContracts.map(lc => ({
                contractId: lc.contractId,
                dateRange: lc.dateRange,
                linkId: lc.linkId,
            }));
        } else {
            return [];
        }
    }

    trackByLinkedContractId = (index: number, linkedContract: LinkedContract) => linkedContract.linkId;

    allowContractDelete = (linkedContract: LinkedContract): boolean => AppContext.isAdminOrIntermediary();

    unlinkContract = (linkedContract: LinkedContract, connection: ExtendedConnection) => {
        sendCommand("com.flowmaps.api.organisation.UnlinkContract", <UnlinkContract>{
            organisationId: connection.organisationId,
            linkId: linkedContract.linkId,
        }, () => {
            connection.connection.linkedContracts = connection.connection.linkedContracts
                .filter(lc => lc.linkId !== linkedContract.linkId);
            AppContext.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
        })
    }

    allowAddMeter = (connection: ExtendedConnection): boolean => connection.connection.info.connectionType === "Electricity";

    isSolarEdgeMeter = (meter: Meter): boolean => meter.info.surveyor?.code === "f31a8249-3d2f-4154-bef1-49eb8d9f17fe";

    allowMeterDelete = (meter: MeterWithConnection): boolean => AppContext.isAdminOrIntermediary() && this.isSolarEdgeMeter(meter.meter);

    trackByMeterId = (index: number, meter: MeterWithConnection) => meter.meter.meterId;

    openConnectionDetails = (connection: ExtendedConnection) => openModal(ConnectionDetailsComponent, <ConnectionComponentData>{
        organisationId: connection.organisationId,
        locationId: connection?.locationId,
        connection: connection?.connection
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    openUpsertSolarEdgeMeter = (connection: ExtendedConnection) => openModal(UpsertSolarEdgeMeterComponent, <UpsertSolarEdgeMeterComponentData>{
        connection: connection.connection
    }, {
        cssClass: "modal-xl",
        animation: AnimationTypes.fade
    });

    openConnectionGraph = (connection: ExtendedConnection) => AppContext.navigateToUrl('/dashboard/' + DashboardContext.dashboardToBase64(DashboardContext.connectionDashboard(connection.connection.connectionId)));

    deleteConnection = (connection: ExtendedConnection) => sendCommand("com.flowmaps.api.organisation.DeleteConnection", <DeleteConnection>{
        organisationId: connection.organisationId,
        locationId: connection.locationId,
        connectionId: connection.connection.connectionId
    }, () => {
        this.removeItem(connection);
        AppContext.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
    });

    deleteMeter = (meter: MeterWithConnection, connection: ExtendedConnection) => sendCommand("com.flowmaps.api.organisation.DeleteMeter", <DeleteMeter>{
        organisationId: connection.organisationId,
        locationId: connection.locationId,
        connectionId: connection.connection.connectionId,
        meterId: meter.meter.meterId
    }, () => {
        connection.connection.meters = connection.connection.meters.filter(m => m.meterId !== meter.meter.meterId);
        AppContext.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
    })

    newConnection = (): ExtendedConnection => ({
        intermediaryId: this.intermediaryId,
        organisationId: this.organisationId,
        locationId: null,
        connection: null,
        organisationInfo: null,
        locationInfo: null
    });

    trackByForRecord = (index: number, connection: ExtendedConnection) => connection.connection.connectionId;

    recordAdded(command: UpsertConnection | UpsertSolarEdgeMeter) {
        const commandClass = command["@class"] as string;
        if (commandClass.endsWith("UpsertConnection")) {
            const upsertCommand = command as UpsertConnection;
            RefdataUtils.getLocation(upsertCommand.locationId).subscribe(l => {
                this.addItem({
                    organisationId: upsertCommand.organisationId,
                    locationId: upsertCommand.locationId,
                    intermediaryId: l.intermediaryId,
                    locationInfo: l.info,
                    connection: {
                        connectionId: upsertCommand.connectionId,
                        info: upsertCommand.info,
                        meters: []
                    },
                    organisationInfo: null
                })
            });
        } else if (commandClass.endsWith("UpsertSolarEdgeMeter")) {
            const upsertCommand = command as UpsertSolarEdgeMeter;
            RefdataUtils.getMyOrganisations()
                .pipe(map(orgs => orgs.flatMap(o => o.locations.flatMap(
                    l => l.connections.filter(c => c.info.code === upsertCommand.ean)))))
                .subscribe(connections => {
                    const connection = connections[0];
                    const existingConnection = this.data.find(l => l.connection.connectionId === connection.connectionId);
                    existingConnection.connection.meters = connection.meters;
                });
        }
    }

    recordUpdated(command: UpsertConnection | UpsertConnections) {
        const commandClass = command["@class"] as string;
        if (commandClass.endsWith("UpsertConnection")) {
            const cmd = command as UpsertConnection;
            this.updateRecord(cmd.connectionId, cmd.info);
        } else if (commandClass.endsWith("UpsertConnections")) {
            const cmd = command as UpsertConnections;
            cmd.updates.forEach(c => this.updateRecord(c.connectionId, c.info));
        }
    }

    updateRecord = (connectionId: string, info: ConnectionInfo) => {
        const extendedConnection = this.data.find(c => c.connection.connectionId === connectionId);
        if (extendedConnection) {
            extendedConnection.connection.info = info;
            this.replaceItem((c: ExtendedConnection) => c.connection.connectionId === connectionId, extendedConnection);
        }
    }

    bulkDownloadMonthData(event: any) {
        downloadBulkMonthData(this.sourceProvider, this.bulkDownloadTimeRange);
    }

    onUploadConnectionsList = (event: any) => {
        uploadConnectionsExcel(event.files[0]).subscribe(command => {
            if (command.updates.length === 0) {
                AppContext.registerError("No changes have been found between uploaded file and current data", 'warning', 3000);
                return;
            }
            const eanCodes = command.updates.map(c => c.info.code).map(ean => `<li>${ean}</li>`).join("");
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    sendCommand("com.flowmaps.api.organisation.UpsertConnections", command, () => {
                        AppContext.registerSuccess('Connections were uploaded successfully');
                        closeModal();
                        this.queryGateway.removeFromCache("com.flowmaps.api.organisation.GetMyOrganisations");
                        publishEvent("recordUpdated", command);
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "warning",
                title: "Bulk update connections",
                innerHtmlMessage: `<p><span>You are about to bulk update the following connections</span></p><ul class="notranslate fw-bold">${eanCodes}</ul><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Update connections",
                cancelText: "Cancel"
            }, 'static');
        });
        event.value = '';
    }

    onUploadConnectionData = (event: any) => {
        parseExcel(event.files[0], connectionDataImportTemplate)
            .subscribe(e => {
                RefdataUtils.getAllConnections()
                    .subscribe(connections => {
                        const connectionWithData = connections.map(c => {
                            const dataPerEan = e.dataPerEan.find(r => r.ean === c.connection.info.code);
                            return dataPerEan ? {
                                connection: c,
                                dataPerEan: dataPerEan
                            } : null;
                        }).filter(c => c);
                        const command: UploadMeterData = {
                            connectionData: {}
                        };
                        connectionWithData.forEach(c => {
                            command.connectionData[c.connection.connection.connectionId] = getDataPointsOfConsumption(
                                e.year, this.getDataType(c.connection.connection), c.dataPerEan.consumptions);
                        });
                        this.uploadConnectionData(connectionWithData, command);
                    });
            });

        event.value = '';


        function getDataPointsOfConsumption(year: number, dataType: DataType, consumptions: { [key: string]: number }):
            { [P in DataType]?: AggregatedDataPoint[] } {
            const returnValue: { [P in DataType]?: AggregatedDataPoint[] } = {};
            Object.entries(consumptions).map(e => {
                const startDate = moment(`01-${e[0]}-${year}`, "DD-MMMM-YYYY");
                return {
                    type: dataType,
                    value: e[1],
                    timeRange: {
                        start: `${startDate.format("YYYY-MM-DDTHH:mm:ss")}`,
                        end: `${startDate.add(1, "month").format("YYYY-MM-DDTHH:mm:ss")}`
                    }
                }
            }).filter(d => d.value).forEach(
                r => {
                    if (!returnValue[r.type]) {
                        returnValue[r.type] = [];
                    }
                    returnValue[r.type].push({
                        value: r.value,
                        timeRange: r.timeRange
                    });
                });
            return returnValue;
        }
    }

    uploadMinuteData = (event: any) => {
        getWorkbook(event.files[0])
            .pipe(mergeMap(workbook => {
                return parseExcel(event.files[0], {
                    sheets: workbook.SheetNames.map(s => ({
                        name: s,
                        template: {
                            [s]: new ArrayTemplate(connectionDataExportTemplate, [2, parseInt(workbook.Sheets[s]["!ref"].match(/(\d+)$/)[0])])
                        }
                    }))
                })
            }))
            .subscribe(data => {
                RefdataUtils.getAllConnections()
                    .subscribe(connections => {
                        const connectionWithData = connections
                            .filter(c => data[c.connection.info.code])
                            .map(c => ({
                                connection: c,
                                dataPerEan: data[c.connection.info.code]
                            }));
                        const command: UploadMeterData = {
                            connectionData: {}
                        };
                        connectionWithData.forEach(c => addConnectionData(c, command));
                        this.uploadConnectionData(connectionWithData, command);
                    });
            });

        let addConnectionData = (c: { dataPerEan: any; connection: ExtendedConnection }, command: UploadMeterData) => {
            const consumptionDataType = this.getDataType(c.connection.connection);
            const productionDataType = this.getProductionDataType(c.connection.connection);
            const result: { [key: string]: AggregatedDataPoint[] } = {
                [consumptionDataType]: []
            };
            if (productionDataType) {
                result[productionDataType] = [];
            }
            c.dataPerEan.forEach(r => {
                if (r.consumption) {
                    result[consumptionDataType].push({
                        value: r.consumption,
                        timeRange: {
                            start: r.start,
                            end: r.end
                        }
                    });
                }
                if (productionDataType && r.feedIn) {
                    result[productionDataType].push({
                        value: r.feedIn,
                        timeRange: {
                            start: r.start,
                            end: r.end
                        }
                    });
                }
            });
            command.connectionData[c.connection.connection.connectionId] = result;
        }
    }

    private uploadConnectionData(connectionWithData: {
        dataPerEan: any;
        connection: ExtendedConnection
    }[], command: UploadMeterData) {
        sendCommand("com.flowmaps.api.measurements.UploadMeterData", command);
    }

    private getDataType = (connection: Connection): DataType => {
        switch (connection.info.connectionType) {
            case "Electricity":
                return DataType.electricityConsumption;
            case "Gas":
                return DataType.gasConsumption;
            case "Heat":
                return DataType.heatConsumption;
            case "Water":
                return DataType.waterConsumption;
        }
    }

    private getProductionDataType = (connection: Connection): DataType => {
        switch (connection.info.connectionType) {
            case "Electricity":
                return DataType.electricityFeedIn;
            default:
                return null;
        }
    }

    downloadMeterAuthorisationPeriods = () => {
        let header = ["Organisation", "Location", "Building code", "EAN", "Market segment",
            "Connection Type", "Meter id", "Meter type", "Start", "End"];
        const data = [header];
        RefdataUtils.getMyOrganisations().subscribe(organisations => organisations.forEach(o => o.locations.forEach(l => l.connections.forEach(c => c.meters.forEach(m => {
            let start = m.timeRange?.start;
            let end = m.timeRange?.end || m.info.authorizedUntil;
            if (end && moment(end).diff(moment(), 'years') > 10) {
                end = null;
            }
            if (end && moment(end).isBefore(moment(start))) {
                start = m.info.authorizedFrom;
            }
            const entry: string[] = [
                RefdataUtils.organisationInfoFormatter(o.info),
                RefdataUtils.locationInfoFormatter(l.info),
                l.info.buildingCode,
                c.info.code,
                c.info.marketSegment,
                c.info.connectionType,
                m.meterId,
                m.info.type,
                start && moment(start).format('DD-MM-YYYY'),
                end && moment(end).format('DD-MM-YYYY')];
            data.push(entry);
        })))));
        exportDataAsExcel(data, 'meter-authorisation-periods.xlsx');
    };

    linkContract(connection: ExtendedConnection) {
        const linkedContract: LinkedContract = {
            contractId: '',
            linkId: '',
            dateRange: {
                start: connection.connection.linkedContractsLastEndDate || '',
                end: ''
            }
        } as LinkedContract;
        openLinkContractToConnectionModal(connection, linkedContract)
    }

    updateLinkedContract(connection: ExtendedConnection, contract: LinkedContract) {
        openLinkContractToConnectionModal(connection, contract)
    }
}

export function downloadRawConnectionData(timeRange: DateFieldRange, resolution: TimeResolution,
                                          selectedItems: ExtendedConnection[], csvMapper: (csv: string) => any[], comparator: ComparatorChain, onDownloaded: () => void = null) {
    sendQuery("com.flowmaps.api.measurements.ExportConnectionData", <ExportConnectionData>{
        timeRange: {
            start: moment(timeRange.start).format(localTimeFormat),
            end: moment(timeRange.end).format(localTimeFormat)
        },
        resolution: resolution,
        ids: selectedItems.map(c => c.connection.connectionId)
    }, {
        showSpinner: true,
        responseType: "text"
    }).subscribe(csv => {
        downloadData(selectedItems.sort(comparator.compare), csvMapper(csv),
            connectionDataExportTemplate);
        if (onDownloaded) {
            onDownloaded();
        }
    })
}

export function connectionCsvDataToJson(csvData: string): ConnectionData[] {
    const data = lodash.reject(csvData.split("\n"), lodash.isEmpty).slice(1);
    return data.map(r => {
        const values = r.split(";");
        const timeRange = values[1].split("|");
        return {
            connectionId: values[0],
            start: timeRange[0],
            end: timeRange[1],
            consumption: lodash.toNumber(values[2]),
            feedIn: lodash.toNumber(values[3])
        }
    });
}

function downloadData(connections: ExtendedConnection[], data: any[], sheetTemplate: any) {
    const dataGrouped = lodash.groupBy(data, c => c.connectionId);
    const template: WorkBookTemplate = {
        sheets: connections.map(c => ({
            name: c.connection.info.code,
            template: {
                [c.connection.connectionId]: new ArrayTemplate(sheetTemplate,
                    [2, (dataGrouped[c.connection.connectionId] || []).length + 1])
            }
        }))
    };
    exportExcelFromWorkBook(downloadWorkbook("/assets/templates/connections-data-download.xlsx")
            .pipe(tap(wb => {
                return connections.forEach(c =>
                    XLSX.utils.book_append_sheet(wb, cloneDeep(wb.Sheets["Connections"]), c.connection.info.code));
            }))
            .pipe(tap(wb => XLSX.utils.book_set_sheet_visibility(wb, "Connections", 2))),
        template, dataGrouped, "connections-data-export.xlsx");
}

export function openLinkContractToConnectionModal(connection: ExtendedConnection, record?: LinkedContract) {
    openModal(LinkContractConnectionComponent, <LinkContractToConnectionComponentData>{
        linkedContract: cloneDeep(record),
        connectionId: connection.connection.connectionId,
        organisationId: connection.organisationId,
        locationId: connection.locationId,
    }, {
        cssClass: "modal-xl custom-modal-wide",
        animation: AnimationTypes.fade
    });
}

interface ConnectionData {
    connectionId: string;
    start: string;
    end: string;
    consumption?: number;
    feedIn?: number;
}

export function downloadAsEdsnCsv(connections: ExtendedConnection[]) {
    const array = [['EAN ODA', 'EAN Aansluiting', 'Begindatum', 'Einddatum', 'Klantnaam']];
    const now = moment();
    const start = now.clone().subtract(2, 'weeks').format('DD-MM-YYYY');
    const end = now.clone().endOf('year').add(1, 'years').add(15, 'days').format('DD-MM-YYYY');
    array.push(...connections.filter(c => c.connection.info.surveyor?.name === 'EDSN')
        .map(c => {
            const name = c.organisationInfo.name.replace(',', '');
            return ['8719333037141', c.connection.info.code, start, end, name];
        }));
    downloadCsv(array, '8719333037141-' + now.format('DDMMYYYY'));
}

const connectionDataImportTemplate = {
    sheets: [
        {
            name: 'Data',
            template: {
                year: new RequiredField('B1'),
                dataPerEan: new ArrayTemplate({
                    ean: new RequiredField('A$'),
                    consumptions: {
                        january: new QuantityField('B$'),
                        february: new QuantityField('C$'),
                        march: new QuantityField('D$'),
                        april: new QuantityField('E$'),
                        may: new QuantityField('F$'),
                        june: new QuantityField('G$'),
                        july: new QuantityField('H$'),
                        august: new QuantityField('I$'),
                        september: new QuantityField('J$'),
                        october: new QuantityField('K$'),
                        november: new QuantityField('L$'),
                        december: new QuantityField('M$'),
                    }
                }, [3, 100])
            }
        }
    ]
}

const connectionDataExportTemplate = {
    start: new ValidatedField(new DateTimeField('A$'), (value: any, cell: Cell, parser: Parser, cellNameFunction) => {
        const start = moment(value);
        const end = moment(Field.getCellValue(parser.mapAny(connectionDataExportTemplate.end, cellNameFunction)));
        if (!start.isValid() || !end.isValid()) {
            throw "Start or end date is not a valid format";
        }
        if (start.isSameOrAfter(end)) {
            throw 'Start date cannot be the same or later then end date.';
        }
    }),
    end: new DateTimeField('B$'),
    consumption: new QuantityField('C$'),
    feedIn: new QuantityField('D$'),
}