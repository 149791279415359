<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end g-3">
                <div class="col">
                    <h6 class="header-pretitle">Overview</h6>
                    <h1 class="header-title notranslate">
                        Dashboard {{ dashboard.info.name ? '- ' + dashboard.info.name : "" }}</h1>
                </div>
                <div class="col-auto">
                    <app-confirmation-toggle *ngIf="dashboard?.dashboardId">
                        <div class="btn btn-outline-danger">Delete</div>
                        <div class="modal-content">
                            <div class="modal-card card">
                                <div class="card-header">
                                    <h4 class="card-header-title">Delete dashboard?</h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div class="card-body">
                                    <p>Are you sure you want to delete this dashboard?</p>
                                </div>
                                <div class="card-footer d-flex justify-content-between">
                                    <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                    <button type="button" class="btn btn-danger"
                                            (click)="deleteDashboard(dashboard.dashboardId)" data-bs-dismiss="modal">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </app-confirmation-toggle>
                </div>
                <div class="col-auto">
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                            <i class="bi bi-download me-md-2"></i>
                            <span class="d-none d-md-inline">Download</span>
                        </button>
                        <ul class="dropdown-menu" style="z-index: 10000">
                            <li>
                                <button class="dropdown-item" (click)="downloadMonthlyData()">Monthly measurement data
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn btn-primary" (click)="openModal(saveDashboardModal)">Save dashboard</div>

                    <div class="btn btn-outline-primary rounded-top ms-4 d-none">
                        <i class="bi bi-pencil-fill pe-sm-2"></i>
                        <span class="d-none d-sm-inline-block">Customize</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div (change)="updateAndNavigateToDashboard()">
    <div class="container-fluid sticky-md-top bg-body" style="z-index: 1050">
        <div class="row gx-3 justify-content-between py-4">
            <div class="col-12 col-md-6 ps-3 pe-md-0">
                <app-sources-selector (updated)="sourceSelectionUpdated($event)"
                                      [sourceProvider]="dataProvider.sourceProvider">
                </app-sources-selector>
            </div>
            <div class="col-12 col-md-6 d-flex justify-content-end">
                <app-data-provider-date-range [dataProvider]="dataProvider"></app-data-provider-date-range>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <app-energy-summary-widget [dataProvider]="dataProvider"></app-energy-summary-widget>
            </div>
        </div>
        <div class="row row-medium">
            <div class="col-12 col-md-8 d-flex align-items-stretch">
                <app-location-performance-chart class="d-flex align-self-stretch flex-grow-1"
                                                [data]="{dataProvider: dataProvider, stacked: true}"></app-location-performance-chart>
            </div>
            <div class="col-4">
                <div class="row h-100">
                    <div class="col d-flex align-items-stretch">
                        <div class="card w-100" style="overflow: auto">
                            <div class="card-body p-0" style="max-height: none">
                                <app-dashboard-maps [locations]="addresses"></app-dashboard-maps>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <app-electricity-chart class="chart"
                                       [data]="{dataProvider: dataProvider, stacked: true}"></app-electricity-chart>
            </div>
            <div class="col-12 col-md-4 d-flex align-items-stretch">
                <app-costs-chart class="card d-flex align-self-stretch flex-grow-1"
                                 [data]="{dataProvider: contractMeasurementsDataProvider}"></app-costs-chart>
            </div>
        </div>
        <div class="row">

        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <app-gas-chart class="chart" [data]="{dataProvider: dataProvider}"></app-gas-chart>
            </div>
            <div class="col-12 col-md-6">
                <app-water-chart class="chart" [data]="{dataProvider:dataProvider}"></app-water-chart>
            </div>
        </div>
        <div class="row row-large">
            <div class="col-12 col-md-8">
                <app-energy-compass-chart [data]="{dataProvider: dataProvider}"></app-energy-compass-chart>
            </div>
            <div class="col-12 col-md-4 d-flex align-items-stretch">
                <app-co2-chart class="card d-flex align-self-stretch flex-grow-1"
                               [data]="{dataProvider: contractMeasurementsDataProvider}"></app-co2-chart>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <app-day-of-week-chart class="d-flex align-self-stretch flex-grow-1"
                                       [data]="{dataProvider: dayOfWeekDataProvider, stacked: true}"></app-day-of-week-chart>
            </div>
        </div>
    </div>
</div>

<ng-template #saveDashboardModal>
    <app-form (submit)="saveDashboard()">
        <div class="modal-content">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-header-title">Save dashboard</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="dashboardName" class="form-label">Name</label>
                        <input type="text" [(ngModel)]="dataProvider.info.name" name="dashboardName"
                               class="form-control" id="dashboardName">
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between">
                        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </app-form>
</ng-template>