<app-report-html-page [pageBreak]="true">
    <div class="container mt-3" *ngIf="sourcesProvider">
        <div class="row" *ngIf="owner">
            <label class="col-2 col-form-label">User</label>
            <span class="col col-form-label notranslate">{{ owner.info.firstName }} {{ owner.info.lastName }}</span>
        </div>
        <div class="row" *ngIf="owner">
            <label class="col-2 col-form-label">Executed on</label>
            <div class="col">
                <label class="form-control-plaintext notranslate">{{ now() }}</label>
            </div>
        </div>
        <app-report-selection [sourcesProvider]="sourcesProvider" [sourceType]="'organisation'"></app-report-selection>
        <app-report-selection [sourcesProvider]="sourcesProvider" [sourceType]="'location'"></app-report-selection>
        <app-report-selection [sourcesProvider]="sourcesProvider" [sourceType]="'connection'"></app-report-selection>
        <div class="row">
            <label class="col-2 col-form-label">Time range</label>
            <div class="col">
                <label class="form-control-plaintext notranslate">{{ formatDate(timeRange.start) }}
                    - {{ formatDate(timeRange.end) }}</label>
            </div>
        </div>
        <div class="row justify-content-between align-items-center mb-0" style="margin-top: 100px;"
             *ngIf="chartDataProvider">
            <div class="col-6">
                <app-costs-chart [data]="{dataProvider: contractMeasurementsDataProvider}" [showInReport]="true"></app-costs-chart>
            </div>
            <div class="col-6">
                <app-co2-chart [data]="{dataProvider: contractMeasurementsDataProvider}" [showInReport]="true"></app-co2-chart>
            </div>
        </div>
    </div>
</app-report-html-page>