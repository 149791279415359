import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {GetOrganisation, Organisation,} from "@flowmaps/flowmaps-typescriptmodels";
import {QueryGateway} from "../../../../flux/query-gateway";
import {uuid} from "../../../../common/utils";
import {sendQuery} from "../../../../flux/flux-utils";
import {ConnectionTypeValues} from "../contracts-list/contracts-list.component";
import {RefdataUtils} from "../../refdata-utils";
import {map, Observable} from "rxjs";
import {AddContractComponentData} from "../add-contract/add-contract.component";
import {TranslateDirective} from "../../../../common/translate.directive";

@Component({
    selector: 'app-contract-info',
    templateUrl: './contract-info.html',
    styleUrls: ['./contract-info.css']
})
export class ContractInfo implements OnInit {
    @Input() data: AddContractComponentData;
    organisation: Organisation;
    isNewRecord: boolean;
    connectionTypes = ConnectionTypeValues;
    protected readonly refDataUtils = RefdataUtils;
    @Input() readonly = false;
    constructor(private queryGateway: QueryGateway, private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        this.isNewRecord = !this.data.contract || !this.data.contract.contractId;
        if (this.isNewRecord) {
            this.data.contract = {
                organisationId: null,
                organisationInfo: undefined,
                contractId: uuid(),
                contractData: {
                    name: '',
                    connectionType: null,
                },
                periods: [],
                defaultContract: false
            };
        } else {
            this.loadOrganisation(this.data.contract.organisationId);
        }
        this.setDefaultOrganisation();
    }

    searchOrganisation = (term: string): Observable<Organisation[]> =>
        RefdataUtils.searchOrganisation(term)
            .pipe(
                map(orgs => orgs.sort(this.customOrganisationComparator))
            );

    private customOrganisationComparator = (a: Organisation, b: Organisation): number => {
        const aIsIntermediary = a.receivedAuthorisations?.some(auth => auth.role === 'intermediary') || false;
        const bIsIntermediary = b.receivedAuthorisations?.some(auth => auth.role === 'intermediary') || false;

        if (aIsIntermediary && !bIsIntermediary) {
            return -1;
        }
        if (!aIsIntermediary && bIsIntermediary) {
            return 1;
        }
        return RefdataUtils.organisationComparator.compare(a, b);
    };

    onOrganisationSelected = (organisation: Organisation) => {
        this.organisation = organisation;
        this.data.contract.organisationId = organisation.organisationId;
        this.data.contract.organisationInfo = organisation.info
    }

    private loadOrganisation(organisationId: string) {
        if (organisationId) {
            sendQuery("com.flowmaps.api.organisation.GetOrganisation", <GetOrganisation>{
                organisationId: organisationId,
            })
                .subscribe(organisation => {
                    this.organisation = organisation;
                });
        }
    }

    onDefaultContractChange() {
        if (this.data.contract.defaultContract) {
            this.setDefaultOrganisation();
        } else {
            this.organisation = null;
            this.data.contract.organisationId = null;
            this.data.contract.organisationInfo = null;
        }
    }

    getToggleLabel(): string {
        if (this.data.contract.defaultContract) {
            return TranslateDirective.getTranslation("yes", true)
        } else {
            return TranslateDirective.getTranslation("no", true)
        }
    }

    private setDefaultOrganisation() {
        this.searchOrganisation('').subscribe(orgs => {
            if (orgs.length > 0) {
                const defaultOrganisation = orgs.find(org =>
                    org.receivedAuthorisations?.some(auth => auth.role === 'intermediary')
                );
                if (defaultOrganisation && this.isNewRecord) {
                    this.organisation = defaultOrganisation;
                    this.data.contract.organisationId = defaultOrganisation.organisationId;
                    this.data.contract.organisationInfo = defaultOrganisation.info;
                }
            }
        });
    }
}

