import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {QueryGateway} from "./flux/query-gateway";
import {HttpClientModule} from "@angular/common/http";
import {CommandGateway} from "./flux/command-gateway";
import {EventGateway} from "./flux/event-gateway";
import {AppRoutingModule} from "./app-routing.module";
import {InjectorProvider} from "./flux/injector-provider";
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {NgChartsModule} from 'ng2-charts';
import {FormsModule} from "@angular/forms";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {DashboardMapsComponent} from './views/dashboard/dashboard-maps/dashboard-maps.component';
import {DashboardSourcesListComponent} from './views/dashboard/dashboard-sources-list/dashboard-sources-list.component';
import {MainMenuComponent} from './views/main-menu/main-menu.component';
import {ElectricityChartComponent} from './views/charts/electricity-chart/electricity-chart.component';
import {GasChartComponent} from './views/charts/gas-chart/gas-chart.component';
import {WaterChartComponent} from './views/charts/water-chart/water-chart.component';
import {SignInComponent} from './views/signed-out/sign-in/sign-in.component';
import {AlertingComponent} from './common/alerting/alerting.component';
import {StatusAlertComponent} from './common/alerting/status-alert/status-alert.component';
import {EditProfileComponent} from './views/edit-profile/edit-profile.component';
import {ForgotPasswordComponent} from './views/signed-out/forgot-password/forgot-password.component';
import {SignedOutComponent} from './views/signed-out/signed-out.component';
import {HomeComponent} from './views/home/home.component';
import {EditProfileSecurityComponent} from './views/edit-profile/edit-profile-security/edit-profile-security.component';
import {EditProfileGeneralComponent} from './views/edit-profile/edit-profile-general/edit-profile-general.component';
import {ActiveWhenUrlMatchesDirective} from './common/active-when-url-matches.directive';
import {ConfirmationModalComponent} from './common/confirmation-modal/confirmation-modal.component';
import {
    DefaultConfirmationDialogComponent
} from './common/confirmation-modal/default-confirmation-dialog/default-confirmation-dialog.component';
import {SignUpComponent} from './views/signed-out/sign-up/sign-up.component';
import {ModalComponent} from "./common/modal/modal.component";
import {
    EnergySummaryWidgetComponent
} from './views/dashboard/widgets/energy-summary-widget/energy-summary-widget.component';
import {
    DataProviderDateRangeComponent
} from "./views/dashboard/data-provider-date-range/data-provider-date-range.component";
import {BootstrapTooltipDirective} from "./common/bootstrap-tooltip.directive";
import {ChartTableComponent} from './views/charts/chart-table/chart-table.component';
import {EncryptComponent} from './views/admin/encrypt/encrypt.component';
import {
    OrganisationsOverviewComponent
} from './views/refdata/organisations/organisations-overview/organisations-overview.component';
import {
    ConnectionsOverviewComponent
} from './views/refdata/connections/connections-overview/connections-overview.component';
import {
    OrganisationDetailsComponent
} from './views/refdata/organisations/organisation-details/organisation-details.component';
import {AddressFormComponent} from './common/address-form/address-form.component';
import {LocationsListComponent} from './views/refdata/locations/locations-list/locations-list.component';
import {LocationDetailsComponent} from './views/refdata/locations/location-details/location-details.component';
import {ConnectionDetailsComponent} from './views/refdata/connections/connection-details/connection-details.component';
import {EnergyLabelComponent} from './common/energy-label/energy-label.component';
import {
    AuthorisationDetailsComponent
} from './views/refdata/authorisations/authorisation-details/authorisation-details.component';
import {
    AuthorisationsListComponent
} from "./views/refdata/authorisations/authorisations-list/authorisations-list.component";
import {TimestampPipe} from "./common/timestamp.pipe";
import {ReportHtmlToPdfComponent} from './views/reports/report-html-to-pdf/report-html-to-pdf.component';
import {ReportHtmlPageComponent} from './views/reports/report-html-page/report-html-page.component';
import {ConnectionImageComponent} from './views/common/connection-image/connection-image.component';
import {
    ServiceProviderSelectComponent
} from './views/refdata/service-provider-select/service-provider-select.component';
import {
    ElectricityDetailsComponent
} from './views/refdata/connections/connection-details/electricity-details/electricity-details.component';
import {GasDetailsComponent} from './views/refdata/connections/connection-details/gas-details/gas-details.component';
import {OrganisationInfoComponent} from './views/refdata/organisations/organisation-info/organisation-info.component';
import {AddOrganisationComponent} from './views/refdata/organisations/add-organisation/add-organisation.component';
import {ConnectionsListComponent} from './views/refdata/connections/connections-list/connections-list.component';
import {
    OrganisationsListComponent
} from './views/refdata/organisations/organisations-list/organisations-list.component';
import {LocationsOverviewComponent} from './views/refdata/locations/locations-overview/locations-overview.component';
import {EnergyCompassChartComponent} from './views/charts/energy-compass-chart/energy-compass-chart.component';
import {
    ReportHtmlFrontPageComponent
} from './views/reports/report-html-page/report-html-front-page/report-html-front-page.component';
import {TagLabelsComponent} from './common/labels/tag-labels.component';
import {ReportDetailsComponent} from './views/reports/report-details/report-details.component';
import {ReportRunnerComponent} from './views/reports/report-runner/report-runner.component';
import {SourcesSelectorComponent} from './views/common/sources-selector/sources-selector.component';
import {ReportComponent} from './views/reports/report/report.component';
import {ReportHeaderComponent} from './views/reports/report-html-page/report-header/report-header.component';
import {
    EnergyCompassTableComponent
} from './views/charts/energy-compass-chart/energy-compass-table/energy-compass-table.component';
import {ScatterChartComponent} from "./views/charts/scatter-chart/scatter-chart.component";
import {BarChartComponent} from "./views/charts/bar-chart/bar-chart.component";
import {DashboardChartTooltipComponent} from "./views/charts/dashboard-chart-tooltip/dashboard-chart-tooltip.component";
import {DoughnutChartComponent} from "./views/charts/doughnut-chart/doughnut-chart.component";
import {Co2ChartLegacyComponent} from './views/charts/co2-chart-legacy/co2-chart-legacy.component';
import {CostsChartComponent} from './views/charts/costs-chart/costs-chart.component';
import {RouteReuseStrategy} from "@angular/router";
import {RouteStrategyService} from "./utils/route-strategy.service";
import {AuthService} from "./utils/auth.service";
import {CommonModule, DecimalPipe, TitleCasePipe} from "@angular/common";
import {ChartUtilsService} from "./views/charts/chart-utils.service";
import {LocationDashboardComponent} from './views/location-dashboard/location-dashboard.component';
import {
    LocationEnergyLabelInfoComponent
} from './views/location-dashboard/location-energy-label-info/location-energy-label-info.component';
import {LocationMetersComponent} from './views/location-dashboard/location-meters/location-meters.component';
import {
    LocationMeterTypeInfoComponent
} from './views/location-dashboard/location-meters/location-meter-type-info/location-meter-type-info.component';
import {MeterImageComponent} from './views/common/meter-image/meter-image.component';
import {
    LocationEnergyCompassComponent
} from './views/location-dashboard/location-energy-compass/location-energy-compass.component';
import {TypeaheadWindowComponent} from "./common/typeahead/typeahead-window.component";
import {PopoverComponent} from "./common/popover/popover.component";
import {HighlightComponent} from "./common/typeahead/highlight/highlight.component";
import {ConfirmUserComponent} from "./views/signed-out/confirm-user/confirm-user.component";
import {ResetPasswordComponent} from "./views/signed-out/reset-password/reset-password.component";
import {CommonsModule} from "./common/commons.module";
import {RenewableFuelUnitsComponent} from './views/charts/renewable-energy/renewable-fuel-units.component';
import {MeterDetailsComponent} from './views/refdata/meters/meter-details/meter-details.component';
import {
    MeterPerformanceChartComponent
} from "./views/charts/entity-performance-chart/meter-performance-chart.component";
import {
    LocationPerformanceChartComponent
} from "./views/charts/entity-performance-chart/location-performance-chart.component";
import {LanguageSelectorComponent} from './common/language-selector/language-selector.component';
import {TranslateDirective} from './common/translate.directive';
import {
    ReportEntityPerformancePageComponent
} from './views/reports/report-html-page/report-entity-performance-page/report-entity-performance-page.component';
import {
    ReportSelectionComponent
} from './views/reports/report-html-page/report-html-front-page/report-selection/report-selection.component';
import {
    ConnectionSelectionComponent
} from "./views/refdata/connections/connection-selection/connection-selection.component";
import {DefaultValueDirective} from './common/default-value.directive';
import {UsersListComponent} from './views/refdata/users/users-list/users-list.component';
import {UserDetailsComponent} from './views/refdata/users/user-details/user-details.component';
import {UsersOverviewComponent} from './views/refdata/users/users-overview/users-overview.component';
import {DndModule} from "ngx-drag-drop";
import {
    UpsertSolarEdgeMeterComponent
} from './views/refdata/meters/upsert-solar-edge-meter/upsert-solar-edge-meter.component';
import {AlertsListComponent} from './views/monitoring/alerting/alerts-list/alerts-list.component';
import {AlertsOverviewComponent} from './views/monitoring/alerting/alerts-overview/alerts-overview.component';
import {AddAlertComponent} from './views/monitoring/alerting/add-alert/add-alert.component';
import {AlertSelectionComponent} from './views/monitoring/alerting/alert-selection/alert-selection.component';
import {DayOfWeekChart} from './views/charts/weekdays-chart/day-of-week-chart.component';
import {
    ConnectionValueSelectComponent
} from './views/refdata/connection-value-select/connection-value-select.component';
import {ErrorsOverviewComponent} from "./views/monitoring/error-monitoring/errors-overview/errors-overview.component";
import {ErrorsListComponent} from "./views/monitoring/error-monitoring/errors-list/errors-list.component";
import {ErrorNavigatorComponent} from './views/monitoring/error-monitoring/error-navigator/error-navigator.component';
import {ErrorEntryComponent} from './views/monitoring/error-monitoring/error-entry/error-entry.component';
import {ErrorSelectionComponent} from "./views/monitoring/error-monitoring/error-selection/error-selection.component";
import {
    ErrorActionsDropdownComponent
} from './views/monitoring/error-monitoring/error-actions-dropdown/error-actions-dropdown.component';
import {
    SlackAlertChannelDetailsComponent
} from './views/monitoring/error-monitoring/alert-channels/slack-alert-channel-details/slack-alert-channel-details.component';
import {ContractsOverviewComponent} from "./views/refdata/contracts/contracts-overview/contracts-overview.component";
import {ContractsListComponent} from "./views/refdata/contracts/contracts-list/contracts-list.component";
import {ContractInfo} from './views/refdata/contracts/contract-info/contract-info';
import {
    ContractPeriodDetailsComponent
} from './views/refdata/contracts/contract-period-details/contract-period-details.component';
import {
    LinkContractConnectionComponent
} from './views/refdata/connections/link-contract-connection/link-contract-connection.component';
import {
    LinkContractOrganisationComponent
} from './views/refdata/organisations/link-contract-organisation/link-contract-organisation.component';
import {ContractDetailsComponent} from './views/refdata/contracts/contract-details/contract-details.component';
import {
    ContractPeriodsListComponent
} from './views/refdata/contracts/contract-periods-list/contract-periods-list.component';
import {LinkedContractsComponent} from './views/refdata/contracts/linked-contracts/linked-contracts.component';
import {AddContractComponent} from './views/refdata/contracts/add-contract/add-contract.component';
import {
    ContractPeriodsOverviewComponent
} from './views/refdata/contracts/contract-periods-overview/contract-periods-overview.component';
import {
    LinkedContractsOverviewComponent
} from './views/refdata/contracts/linked-contracts-overview/linked-contracts-overview.component';
import {CostsChartLegacyComponent} from './views/charts/costs-chart-legacy/costs-chart-legacy.component';
import {LinkContractComponent} from './views/refdata/contracts/link-contract/link-contract.component';
import {ContractRangeComponent} from './views/refdata/contracts/contract-range/contract-range.component';
import {
    SustainabilitySourceSelectComponent
} from './views/refdata/sustainability-source-select/sustainability-source-select.component';
import {TaxesOverviewComponent} from './views/refdata/taxes/taxes-overview/taxes-overview.component';
import {TaxesListComponent} from './views/refdata/taxes/taxes-list/taxes-list.component';
import {AddTaxComponent} from './views/refdata/taxes/add-tax/add-tax.component';
import {AddTaxPeriodComponent} from './views/refdata/taxes/add-tax-period/add-tax-period.component';
import {Co2ChartComponent} from './views/charts/co2-chart/co2-chart.component';
import {ChartDownloadButton} from "./views/charts/chart-download-button/chart-download-button.component";

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        MainMenuComponent,

        // Directives
        ActiveWhenUrlMatchesDirective,
        BootstrapTooltipDirective,

        TimestampPipe,

        // Common
        AlertingComponent,
        StatusAlertComponent,
        ConfirmationModalComponent,
        DefaultConfirmationDialogComponent,
        ModalComponent,

        // Dashboard
        DashboardMapsComponent,
        BarChartComponent,
        DashboardChartTooltipComponent,
        DashboardSourcesListComponent,

        // Chart
        DoughnutChartComponent,
        ElectricityChartComponent,
        GasChartComponent,
        WaterChartComponent,
        SignInComponent,
        EditProfileComponent,
        ForgotPasswordComponent,
        SignedOutComponent,
        HomeComponent,
        EditProfileSecurityComponent,
        EditProfileGeneralComponent,
        SignUpComponent,
        EnergySummaryWidgetComponent,
        DataProviderDateRangeComponent,
        ChartTableComponent,
        EncryptComponent,
        OrganisationsOverviewComponent,
        ConnectionsOverviewComponent,
        OrganisationDetailsComponent,
        AddressFormComponent,
        AuthorisationsListComponent,
        LocationsListComponent,
        LocationDetailsComponent,
        ConnectionDetailsComponent,
        EnergyLabelComponent,
        AuthorisationDetailsComponent,
        ConnectionImageComponent,
        ServiceProviderSelectComponent,
        ElectricityDetailsComponent,
        GasDetailsComponent,
        OrganisationInfoComponent,
        AddOrganisationComponent,
        ConnectionsListComponent,
        OrganisationsListComponent,
        LocationsOverviewComponent,
        ReportHtmlToPdfComponent,
        ReportHtmlPageComponent,
        ReportHtmlFrontPageComponent,
        TagLabelsComponent,
        ReportDetailsComponent,
        ReportRunnerComponent,
        SourcesSelectorComponent,
        ReportComponent,
        ReportHeaderComponent,
        ScatterChartComponent,
        EnergyCompassChartComponent,
        EnergyCompassTableComponent,
        Co2ChartLegacyComponent,
        CostsChartComponent,
        LocationDashboardComponent,
        LocationEnergyLabelInfoComponent,
        LocationMetersComponent,
        LocationMeterTypeInfoComponent,
        MeterImageComponent,
        LocationEnergyCompassComponent,
        ConfirmUserComponent,
        ResetPasswordComponent,
        RenewableFuelUnitsComponent,
        MeterDetailsComponent,
        MeterPerformanceChartComponent,
        LocationPerformanceChartComponent,
        ReportEntityPerformancePageComponent,
        ReportSelectionComponent,
        LocationPerformanceChartComponent,
        LanguageSelectorComponent,
        TranslateDirective,
        ConnectionSelectionComponent,
        DefaultValueDirective,
        UsersListComponent,
        UserDetailsComponent,
        UsersOverviewComponent,
        UpsertSolarEdgeMeterComponent,
        AlertsListComponent,
        AlertsOverviewComponent,
        AddAlertComponent,
        AlertSelectionComponent,
        ConnectionValueSelectComponent,
        DayOfWeekChart,
        ErrorsOverviewComponent,
        ErrorsListComponent,
        ErrorNavigatorComponent,
        ErrorEntryComponent,
        ErrorSelectionComponent,
        ErrorActionsDropdownComponent,
        SlackAlertChannelDetailsComponent,
        ContractsOverviewComponent,
        ContractsListComponent,
        ContractInfo,
        ContractPeriodDetailsComponent,
        LinkContractConnectionComponent,
        LinkContractOrganisationComponent,
        OrganisationDetailsComponent,
        ContractDetailsComponent,
        ContractPeriodsListComponent,
        LinkedContractsComponent,
        AddContractComponent,
        ContractPeriodsOverviewComponent,
        LinkedContractsOverviewComponent,
        CostsChartLegacyComponent,
        LinkContractComponent,
        ContractRangeComponent,
        SustainabilitySourceSelectComponent,
        TaxesOverviewComponent,
        TaxesListComponent,
        AddTaxComponent,
        AddTaxPeriodComponent,
        Co2ChartComponent,
        ChartDownloadButton,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgChartsModule,
        LeafletModule,
        CommonModule,
        FormsModule,
        CommonsModule,
        TypeaheadWindowComponent,
        PopoverComponent,
        HighlightComponent,
        DndModule
    ],
    providers: [
        CommandGateway,
        QueryGateway,
        EventGateway,
        TitleCasePipe,
        TimestampPipe,
        DecimalPipe,
        AuthService,
        ChartUtilsService,
        {provide: RouteReuseStrategy, useClass: RouteStrategyService},
        {provide: LOCALE_ID, useValue: 'nl-NL'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        InjectorProvider.injector = injector;
    }
}