import {Component, NgZone, OnDestroy} from '@angular/core';
import {AppContext} from "../../app-context";
import {ExtendedLocation} from "../refdata/locations/locations-list/locations-list.component";
import {takeUntil} from "rxjs";
import {SourceInfo} from "../../utils/source-providers/sources-provider";
import {ActivatedRoute, Router} from "@angular/router";
import {ChartUtilsService} from "../charts/chart-utils.service";
import {DashboardContext} from "../dashboard/dashboard.context";
import {Dashboard, DeleteDashboard, SaveDashboard} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, lodash, removeIf, uuid} from "../../common/utils";
import {sendCommand} from "../../flux/flux-utils";
import {RefdataUtils} from "../refdata/refdata-utils";
import {LocationProvider} from "../../utils/source-providers/location-provider";
import {DashboardType} from "../dashboard/dashboard.types";
import {openModal} from "../../app-utils";
import {MeterMeasurementsDataProvider} from "../../utils/meter-measurements-data-provider";
import {cloneDeep} from "lodash";
import {DayOfWeekMeasurementsDataProvider} from "../../utils/day-of-week-measurements-data-provider";
import {ContractMeasurementsDataProvider} from "../../utils/contract-measurements-data-provider";


@Component({
    selector: 'app-location-dashboard',
    templateUrl: './location-dashboard.component.html',
    styleUrls: ['./location-dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class LocationDashboardComponent implements OnDestroy {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    protected readonly openModal = openModal;

    locationIds: string[];
    location: ExtendedLocation;
    locations: ExtendedLocation[];
    dataProvider: MeterMeasurementsDataProvider;
    contractMeasurementsDataProvider: ContractMeasurementsDataProvider;
    dayOfWeekDataProvider: DayOfWeekMeasurementsDataProvider;
    dashboard: Dashboard = {info: {}};

    constructor(private ngZone: NgZone, private router: Router, route: ActivatedRoute, chartUtils: ChartUtilsService) {
        this.dataProvider = new MeterMeasurementsDataProvider(chartUtils);
        this.dayOfWeekDataProvider = new DayOfWeekMeasurementsDataProvider(chartUtils);
        this.contractMeasurementsDataProvider = new ContractMeasurementsDataProvider(chartUtils);
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.updateAndNavigateToDashboard());
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.dayOfWeekDataProvider?.timeChanged(t));
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.contractMeasurementsDataProvider?.timeChanged(t));
        route.params.subscribe(params => {
            route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : LocationDashboardComponent.defaultDashboard();
                this.dataProvider.ranges = DashboardContext.defaultRanges;
                this.dataProvider.info = this.dashboard.info;
                this.dataProvider.sourceProvider = new LocationProvider();
                this.dataProvider.sourceProvider.selectedSources = this.dataProvider.info.sources;
                this.dataProvider.sourceProvider.selectionUpdated.pipe(takeUntil(this.dataProvider.notifier))
                    .subscribe(t => this.sourceSelectionUpdated(t));
                this.locationIds = this.dataProvider.info.sources.locationIds;
                if (this.dayOfWeekDataProvider) {
                    this.dayOfWeekDataProvider.ranges = DashboardContext.defaultRanges;
                    this.dayOfWeekDataProvider.info = this.dashboard.info;
                    this.dayOfWeekDataProvider.sourceProvider = this.dataProvider.sourceProvider;
                }
                RefdataUtils.getAllLocations().subscribe(locations => {
                    this.locations = locations;
                    if (this.dataProvider.info.sources.locationIds) {
                        this.location = locations.find(l => this.locationIds.includes(l.locationId));
                        if (this.location) {
                            setTimeout(() => this.updateLocation(this.location), 0);
                        }
                    }
                });
                if (this.contractMeasurementsDataProvider) {
                    this.contractMeasurementsDataProvider.info = this.dataProvider.info;
                    this.contractMeasurementsDataProvider.sourceProvider = this.dataProvider.sourceProvider;
                }
            });
        });
    }

    updateLocation(location: ExtendedLocation) {
        this.locationIds = [location.locationId, ...location.aliasIds];
        this.location = location;
        this.dataProvider.sourceProvider.setData([location]);
        this.sourceSelectionUpdated([this.dataProvider.sourceProvider.findById(location.locationId).info]);
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            this.updateAndNavigateToDashboard();
            this.dataProvider.sourceChanged(sources);
            this.dayOfWeekDataProvider?.sourceChanged(sources);
            this.contractMeasurementsDataProvider?.sourceChanged(sources);
        });
    }

    ngOnDestroy(): void {
        this.dataProvider.notifier.next(null);
        this.dataProvider.notifier.complete();
        this.dayOfWeekDataProvider?.notifier.next(null);
        this.dayOfWeekDataProvider?.notifier.complete();
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        sendCommand("com.flowmaps.api.user.SaveDashboard", command, () => {
            AppContext.userProfile.dashboards.push(savedDashboard);
            AppContext.navigateToUrl("/location/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard)));
        });
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppContext.navigateToUrl("/location/dashboard");
            }
        });
    }

    updateAndNavigateToDashboard() {
        this.ngZone.run(() => {
            const dashboard = cloneDeep(this.dashboard);
            this.dashboard.info.sources = this.dataProvider.sourceProvider.selectedSources;
            if (this.locationIds) {
                this.dataProvider.info.sources.locationIds = this.locationIds;
            }
            return lodash.isEqual(dashboard, this.dashboard) ? Promise.resolve()
                : this.router.navigateByUrl(`/location/dashboard/${DashboardContext.dashboardToBase64(this.dashboard)}`);
        });
    }

    static defaultDashboard = (): Dashboard => {
        const dashboard = DashboardContext.defaultDashboard();
        dashboard.info.type = DashboardType.Location;
        dashboard.info.chartOptions["Electricity"].splitOffPeak = false;
        dashboard.info.chartOptions["Electricity"].groupByEntityId = true;
        dashboard.info.chartOptions["Electricity"].showPower = true;
        dashboard.info.chartOptions["Electricity"].showGrossProduction = false;
        dashboard.info.chartOptions["Water"].groupByEntityId = true;
        return dashboard;
    }
}