<div class="container-fluid bg-body">

    <!-- Button -->
    <div class="header">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row justify-content-end g-2">
                    <div class="col">
                        <ng-container *ngIf="_report?.info?.name else defaultHeader">
                            <h1 class="header-title">{{_report?.info?.name}}</h1>
                        </ng-container>
                        <ng-template #defaultHeader>
                            <h1 class="header-title">Default</h1>
                        </ng-template>
                    </div>
                    <div class="col-auto">
                        <app-confirmation-toggle *ngIf="this._report?.reportId">
                            <div class="btn btn-outline-danger">Delete</div>
                            <div class="modal-content">
                                <div class="modal-card card">
                                    <div class="card-header">
                                        <h4 class="card-header-title">Delete report?</h4>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                    </div>
                                    <div class="card-body">
                                        <p>Are you sure you want to delete this report? This will cancel any further emails.</p>
                                    </div>
                                    <div class="card-footer d-flex justify-content-between">
                                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                                        <button type="button" class="btn btn-danger" (click)="deleteReport()" data-bs-dismiss="modal">Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </app-confirmation-toggle>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary " (click)="download()">
                            Download
                        </button>
                    </div>
                    <div class="col-auto">
                        <ng-container *ngIf="timeRange.label else disabledButton">
                            <button class="btn btn-primary" (click)="saveReport()">
                                <span>Save report</span>
                            </button>
                        </ng-container>
                        <ng-template #disabledButton>
                            <span class="d-inline-block" tabindex="0" data-bs-title="Select one of the predefined Period options">
                                <button class="btn btn-primary" (click)="saveReport()" disabled>
                                    <span>Save report</span>
                                    <i class="bi bi-exclamation-triangle-fill ms-2"></i>
                                </button>
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="_report">
        <app-form-field class="col-12 col-md-6" label="Sources">
            <app-sources-selector (updated)="sourceSelectionUpdated($event)" [sourceProvider]="searchSourceProvider">
            </app-sources-selector>
        </app-form-field>
        <app-form-field class="ms-auto col-12 col-md-auto" label=" " [renderOptional]="false">
            <app-facet-date-range (ngModelChange)="timeRangeChanged($event)" [(ngModel)]="timeRange"
                                  [ranges]="reportUtils.defaultRanges" [publishDefaultModelChange]="false"></app-facet-date-range>
        </app-form-field>
    </div>

    <div class="row d-flex justify-content-center align-items-center" *ngIf="chartDataProvider">
        <div class="pdf-content w-100 h-100" *ngIf="_report">
            <app-report-header #header [logoPath]="'/assets/images/logos/' + appContext.brand + '-logo-500.png'" header="{{rangeResolution}} comparison per {{_report.info.query.resolution}}"></app-report-header>
        </div>
        <div #pdfContent class="pdf-content w-100 h-100" *ngIf="_report">
            <app-report-html-front-page [chartDataProvider]="chartDataProvider" [sourcesProvider]="searchSourceProvider"
                                        [owner]="owner" [timeRange]="timeRange" [contractMeasurementsDataProvider]="contractMeasurementsDataProvider"></app-report-html-front-page>
            <app-report-html-page *ngIf="hasSourceForConnectionType('Electricity')" [pageBreak]="true">
                <div class="row justify-content-between align-items-center mb-0">
                    <h4 class="my-3">
                        <app-connection-image [connectionType]="'Electricity'"></app-connection-image>
                        Electricity consumption
                        <app-tooltip class="ms-2" placement="top" *ngIf="appContext.isAdmin()">
                            <span class="notranslate" [style.color]="completenessColor(['Electricity'])">{{completeness(['Electricity'])}}%</span>
                            <div class="tooltipContent">
                                <span class="notranslate">{{completenessTitle(['Electricity'])}}</span>
                            </div>
                        </app-tooltip>
                    </h4>
                    <app-electricity-chart [data]="chartDataProvider" [showInReport]="true"></app-electricity-chart>
                </div>
            </app-report-html-page>
            <app-report-html-page *ngIf="hasSourceForConnectionType('Gas')" [pageBreak]="true">
                <div class="row justify-content-between align-items-center mb-0">
                    <h4 class="my-3">
                        <app-connection-image [connectionType]="'Gas'"></app-connection-image>
                        Gas and heat consumption
                        <app-tooltip class="ms-2" placement="top" *ngIf="appContext.isAdmin()">
                            <span class="notranslate" [style.color]="completenessColor(['Gas', 'Heat'])">{{completeness(['Gas', 'Heat'])}}%</span>
                            <div class="tooltipContent">
                                <span class="notranslate">{{completenessTitle(['Gas', 'Heat'])}}</span>
                            </div>
                        </app-tooltip>
                    </h4>
                    <app-gas-chart [data]="chartDataProvider" [showInReport]="true"></app-gas-chart>
                </div>
            </app-report-html-page>
            <app-report-html-page *ngIf="hasSourceForConnectionType('Water')" [pageBreak]="true">
                <div class="row justify-content-between align-items-center mb-0">
                    <h4 class="my-3">
                        <app-connection-image [connectionType]="'Water'"></app-connection-image>
                        Water consumption
                        <app-tooltip class="ms-2" placement="top" *ngIf="appContext.isAdmin()">
                            <span class="notranslate" [style.color]="completenessColor(['Water'])">{{completeness(['Water'])}}%</span>
                            <div class="tooltipContent">
                                <span class="notranslate">{{completenessTitle(['Water'])}}</span>
                            </div>
                        </app-tooltip>
                    </h4>
                    <app-water-chart [data]="chartDataProvider" [showInReport]="true"></app-water-chart>
                </div>
            </app-report-html-page>
            <app-report-entity-performance-page [connectionType]="'Electricity'"
                                                [chartDataProvider]="chartDataProvider"
                                                [consumptionType]="electricityConsumption"
                                                [productionType]="electricityFeedIn">
            </app-report-entity-performance-page>
            <app-report-entity-performance-page [connectionType]="'Gas'"
                                                [chartDataProvider]="chartDataProvider"
                                                [consumptionType]="gasConsumption">
            </app-report-entity-performance-page>
            <app-report-entity-performance-page [connectionType]="'Water'"
                                                [chartDataProvider]="chartDataProvider"
                                                [consumptionType]="waterConsumption">
            </app-report-entity-performance-page>
        </div>
    </div>
</div>

