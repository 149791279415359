<div #container class="d-flex align-items-stretch" style="flex: 1" [ngClass]="{'mb-0': _dataOptions.fullScreen || showInReport, 'card': !showInReport, 'report-view': showInReport}">
    <ng-container *ngIf="!showInReport">
        <div class="card-header dropdown">
            <h4 class="card-header-title">
                <img src="assets/images/icons/ranking.png" width="20px" class="me-2">
                <span>{{entityType() | titlecase}} ranking</span>
                <span class="mx-2 notranslate">–</span>
                <span class="notranslate">{{measurementName(getSelectedDataType, true)}}</span>
                <span class="notranslate">&nbsp;</span>
                <span *ngIf="options.showBySquareMeter">per m²</span>
            </h4>
            <app-chart-download-button [element]="container" [tableElement]="tableElement"
                                       [chartProvider]="_dataOptions.dataProvider" [connectionTypes]="[connectionType()]"></app-chart-download-button>
            <app-data-provider-date-range *ngIf="_dataOptions.fullScreen" [dataProvider]="_dataOptions.dataProvider"></app-data-provider-date-range>
            <button type="button" class="btn" role="button" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <div class="dropdown-menu" style="z-index: 1050;">
                <div class="dropdown-item" *ngIf="perSquareMeterEnabled">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" [id]="getInputId('showBySquareMeter')"
                               [ngModel]="options.showBySquareMeter" (ngModelChange)="showBySquareMeterChange($event)">
                        <label class="form-check-label" [for]="getInputId('showBySquareMeter')">
                            Show by m²
                        </label>
                    </div>
                </div>
                <div class="dropdown-item">
                    <div class="row">
                        <label class="form-check-label col-auto" [for]="getInputId('measurementType')+'-select'">Measurement</label>
                        <app-select class="col px-0" [ngModel]="getSelectedDataType" (ngModelChange)="measurementTypeChanged($event)" [id]="getInputId('measurementType')"
                                    [options]="possibleDataTypes()" [small]="true" [formatter]="dropdownFormatter"></app-select>
                    </div>
                </div>
            </div>
            <button *ngIf="_dataOptions.fullScreen" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
    </ng-container>
    <div class="chart-body pt-0 pb-0" [ngClass]="{'p-0': options.showAsTable, 'card-body': !showInReport}">
        <ng-container *ngIf="showInReport else notInReport">
            <ng-container [ngTemplateOutlet]="chart"></ng-container>
        </ng-container>
        <ng-template #notInReport>
            <ng-container *ngIf="options.showAsTable else showChart">
                <ng-container [ngTemplateOutlet]="tableView"></ng-container>
            </ng-container>
            <ng-template #showChart>
                <ng-container [ngTemplateOutlet]="chart"></ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>

<ng-template #chart>
    <ng-container *ngIf="showInReport else normalView">
        <app-bar-chart class="chart h-100 w-100 small-chart" [stacked]="true" [y1AxisMeasurement]="measurementUnit()"
                       style="flex: 1;" [measurementsProvider]="_dataOptions.dataProvider"
                       [y2AxisMeasurement]="y2AxisTitle" [powerAxisMeasurement]="powerAxisTitle"
                       [dataProvider]="chartDataProvider" [showNormalAxis]="showDataNormally()"
                       [selectedWeatherTypes]="options.selectedWeatherTypes" [weatherTypes]="weatherTypes"
                       [fullScreen]="_dataOptions.fullScreen" [animationDuration]="showInReport ? 0 : 1000"
                       [horizontally]="true" [customOnClick]="navigateToEntity" [customHover]="onHover"
                       [topPadding]="showInReport ? -100 : 0">
        </app-bar-chart>
    </ng-container>
    <ng-template #normalView>
        <app-pagination [maxPerPage]="pageSize" [items]="records" (pageChangeItems)="applyPaging($event)">
            <app-bar-chart class="chart h-100 w-100" [stacked]="true" [y1AxisMeasurement]="measurementUnit()"
                           style="flex: 1" [measurementsProvider]="_dataOptions.dataProvider"
                           [y2AxisMeasurement]="y2AxisTitle" [powerAxisMeasurement]="powerAxisTitle"
                           [dataProvider]="chartDataProvider" [showNormalAxis]="showDataNormally()"
                           [selectedWeatherTypes]="options.selectedWeatherTypes" [weatherTypes]="weatherTypes"
                           [fullScreen]="_dataOptions.fullScreen" [animationDuration]="showInReport ? 0 : 1000"
                           [horizontally]="true" [customOnClick]="navigateToEntity" [customHover]="onHover">
            </app-bar-chart>
        </app-pagination>
    </ng-template>
</ng-template>

<ng-template #tableView>
    <app-chart-table class="w-100" [dataProvider]="chartDataProvider"
                     [measurementsProvider]="_dataOptions.dataProvider"
                     [compare]="options.compare"></app-chart-table>
</ng-template>