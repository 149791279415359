import {Component, Input, ViewChild} from '@angular/core';
import {BaseMeasurementChartComponent, ChartDatasetExtended, MeasurementDataset} from "../base-measurement-chart";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartOptions, ChartOptionType} from "../../dashboard/dashboard.types";
import {DashboardContext, SourceType} from "../../dashboard/dashboard.context";
import {lodash} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import {Observable, of} from "rxjs";
import {PaginationComponent} from "../../../common/pagination/pagination.component";
import {ActiveElement, Chart, ChartEvent} from "chart.js";
import {TranslateDirective} from "../../../common/translate.directive";

@Component({
    template: "",
})
export abstract class EntityPerformanceChartComponent<EntityType> extends BaseMeasurementChartComponent {
    _direction: 'ASC' | 'DESC' = 'DESC';
    _rawDataset: ChartDataPerMeasurement;
    weatherTypes: DataType[] = [];
    records: PerformanceRecord[] = [];
    perSquareMeterEnabled = false;
    currentPageRecords: PerformanceRecord[] = [];

    abstract entityType(): SourceType;
    abstract possibleDataTypes: () => DataType[];
    abstract createData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]): Observable<PerformanceRecord[]>;
    abstract getAllEntities(): Observable<EntityType[]>;
    navigateToEntity: ((event: ChartEvent, elements: ActiveElement[], chart: Chart) => void) | boolean = false;
    onHover: ((event: ChartEvent, elements: ActiveElement[], chart: Chart) => void) | boolean = false;

    @ViewChild("pagination") pagination: PaginationComponent<any>;

    @Input() pageSize: number = 10;
    @Input() showBySquareMeter: boolean;

    get options(): ChartOptions {
        const opts = super.options;
        opts.selectedDataType = opts.selectedDataType || DataType.electricityConsumption;
        return opts;
    }
    
    get getSelectedDataType(): DataType {
        return this.options.selectedDataType;
    }
    
    @Input()
    set sortingDirection(direction: 'ASC' | 'DESC') {
        this._direction = direction;
        this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
    }

    measurementTypes = (): DataType[] => {
        if (this.getSelectedDataType === DataType.electricityConsumption) {
            return [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak];
        }
        if (this.getSelectedDataType === DataType.electricityFeedIn) {
            return [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak];
        }
        if (this.getSelectedDataType === DataType.electricityConsumptionReactive) {
            return [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak,
                DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak];
        }
        return [this.getSelectedDataType];
    };

    productionDataTypes = (): DataType[] => [];

    powerDataType = (): DataType => null;

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    connectionType = (): ConnectionType => null;

    openModal = () => this.openModalWithType(EntityPerformanceChartComponent);

    measurementUnit = (): string => `${DashboardContext.getMeasurementUnit(this.getSelectedDataType)}${this.showPerSquareMeter() ? '/m²' : ''}`;

    optionsType = (): ChartOptionType => ChartOptionType.Performance;

    get y2AxisTitle(): string {
        const hasPowerSelected = this.getSelectedConnections().length === 1 && this.options.showPower;
        return !hasPowerSelected ? super.y2AxisTitle : null;
    }

    get powerAxisTitle(): string {
        const hasWeatherSelected = this.options.selectedWeatherTypes?.length > 0;
        const hasPowerSelected = this.getSelectedConnections().length === 1 && this.options.showPower;
        return !hasWeatherSelected && hasPowerSelected ? "kW" : null;
    }

    measurementTypeChanged = (m: DataType) => {
        this.options.selectedDataType = m;
        this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
    }

    protected refreshData(emitData: boolean) {
        this.setData(this._rawDataset, this._rawData, this._rawEstimatedData);
    }

    setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
        this._rawDataset = result;
        this._rawData = measurements;
        this._rawEstimatedData = estimatedMeasurements;
        this.createData(result, measurements, estimatedMeasurements).subscribe(data => {
            let dataSorted = lodash.sortBy(data, a => {
                const value = a.value ? a.value : 0;
                const estimatedValue = a.estimatedValue ? a.estimatedValue : 0;
                return value + estimatedValue;
            });
            if (this._direction === "DESC") {
                dataSorted = dataSorted.reverse();
            }
            this.records = dataSorted;
            this.applyPaging(dataSorted.slice(0, this.pageSize));
        });
    }

    applyPaging = (items: PerformanceRecord[]) => {
        this.currentPageRecords = items;
        const estimatedData = items.map(a => a.estimatedValue);
        this.chartDataProvider.emit({
            labels: items.map(d => d.label),
            datasets: [{
                entityId: null,
                measurementType: this.getSelectedDataType,
                dataset: this.getDataset(false, items.map(a => a.value))
            }].concat(estimatedData.some(a => a > 0) ? [{
                entityId: null,
                measurementType: this.getSelectedDataType,
                dataset: this.getDataset(true, estimatedData)
            }] : [])
        });
    }

    protected getEntities = (): Observable<EntityType[]> => {
        const selectedEntities: EntityType[] = this._dataOptions.dataProvider.sourceProvider.getSelection()
            .filter(t => t.type === this.entityType())
            .map(t => t.source as EntityType);
        return selectedEntities.length > 0 ? of(selectedEntities) : this.getAllEntities();
    }

    private getDataset = (estimated: boolean, data: number[]): ChartDatasetExtended => {
        const color = AppContext.getChartColorForMeasurementAsString(this.getSelectedDataType);
        const label = this.measurementName(this.getSelectedDataType);
        return {
            label: estimated ? `${label} (${TranslateDirective.getTranslation("estimated", true)})` : label,
            data: data,
            backgroundColor: estimated ? "#FFFFFF" : color,
            borderColor: color,
            borderWidth: estimated ? 1 : 0,
            measurementType: this.getSelectedDataType,
            tooltip: {
                formatter: this.chartUtils.getCustomTooltipFormatter(this.getSelectedDataType, this.showPerSquareMeter()),
                labelOverride: this.measurementName(this.getSelectedDataType),
                data: data,
                estimated: estimated
            }
        }
    }

    dropdownFormatter = (measurementType: DataType) => this.measurementName(measurementType, false);

    measurementName = (measurementType: DataType, translate: boolean = true): string => {
        return AppContext.entityPerformanceMeasurementName(measurementType, translate);
    }

    showPerSquareMeter = (): boolean => {
        const showBySquareMeter = this.showBySquareMeter !== undefined ? this.showBySquareMeter : this.options.showBySquareMeter;
        return this.perSquareMeterEnabled && showBySquareMeter;
    };
}

export interface PerformanceRecord {
    entityId: string;
    value: number;
    estimatedValue: number;
    label: string;
}