import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardContext, SourceType} from "./dashboard.context";
import {sendCommand} from "../../flux/flux-utils";
import {takeUntil} from "rxjs";
import {Dashboard, DeleteDashboard, Location, SaveDashboard} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, removeIf, uuid} from "../../common/utils";
import {AppContext} from "../../app-context";
import {ActivatedRoute, Router} from "@angular/router";
import {SourceInfo, TreeViewItem} from "../../utils/source-providers/sources-provider";
import {ChartUtilsService} from "../charts/chart-utils.service";
import {openModal} from "../../app-utils";
import {PortfolioMeasurementsDataProvider} from "../../utils/portfolio-measurements-data-provider";
import {downloadBulkMonthData} from "../refdata/connections/connections-list/bulk-data-download";
import {DayOfWeekMeasurementsDataProvider} from "../../utils/day-of-week-measurements-data-provider";
import {ContractMeasurementsDataProvider} from "../../utils/contract-measurements-data-provider";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
export class DashboardComponent implements OnInit, OnDestroy {
    appContext = AppContext;
    context = DashboardContext;
    protected readonly openModal = openModal;

    @ViewChild('leftMenuCollapse', {static: true}) filtersModal: ElementRef;
    @ViewChild('sourceListDropdown') sourceListDropdown: ElementRef;

    addresses: Location[] = [];

    dataProvider: PortfolioMeasurementsDataProvider;
    contractMeasurementsDataProvider: ContractMeasurementsDataProvider;
    dayOfWeekDataProvider: DayOfWeekMeasurementsDataProvider;
    dashboard: Dashboard = { info: {}};

    constructor(private ngZone: NgZone, private router: Router, route: ActivatedRoute, chartUtils: ChartUtilsService) {
        this.dataProvider = new PortfolioMeasurementsDataProvider(chartUtils);
        this.dayOfWeekDataProvider = new DayOfWeekMeasurementsDataProvider(chartUtils);
        this.contractMeasurementsDataProvider = new ContractMeasurementsDataProvider(chartUtils);
        route.params.subscribe(params => {
            route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : DashboardContext.defaultDashboard();
                this.dataProvider.info = this.dashboard.info;
                this.dataProvider.sourceProvider.selectedSources = this.dataProvider.info.sources;
                this.dataProvider.ranges = DashboardContext.defaultRanges;
                if (this.dayOfWeekDataProvider) {
                    this.dayOfWeekDataProvider.info = this.dataProvider.info;
                    this.dayOfWeekDataProvider.sourceProvider = this.dataProvider.sourceProvider;
                }
                if (this.contractMeasurementsDataProvider) {
                    this.contractMeasurementsDataProvider.info = this.dataProvider.info;
                    this.contractMeasurementsDataProvider.sourceProvider = this.dataProvider.sourceProvider;
                }
            });
        });
    }

    ngOnInit(): void {
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => {
                this.updateAndNavigateToDashboard();
                this.dayOfWeekDataProvider?.timeChanged(t);
                this.contractMeasurementsDataProvider?.timeChanged(t);
            });
        this.dataProvider.sourceProvider.selectionUpdated.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.sourceSelectionUpdated(t));
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            this.updateAndNavigateToDashboard();
            this.dataProvider.sourceChanged(sources);
            this.dayOfWeekDataProvider?.sourceChanged(sources);
            this.contractMeasurementsDataProvider?.sourceChanged(sources);
            this.addresses = this.getAddresses();
        });
    }

    ngOnDestroy(): void {
        this.dataProvider.notifier.next(null);
        this.dataProvider.notifier.complete();
        this.dayOfWeekDataProvider?.notifier.next(null);
        this.dayOfWeekDataProvider?.notifier.complete();
        this.contractMeasurementsDataProvider?.notifier.next(null);
        this.contractMeasurementsDataProvider?.notifier.complete();
    }

    private getAddresses() {
        const sourceSelection = this.dataProvider.sourceProvider.getTreeViewSelection();
        const items = sourceSelection.length > 0 ? sourceSelection : this.dataProvider.sourceProvider.flattenedData;
        return items.flatMap(this.getLocations);
    }

    private getLocations(item: TreeViewItem): Location[] {
        switch (item.info.type) {
            case SourceType.organisation:
                return item.info.source.locations;
            case SourceType.location:
                return [item.info.source];
            case SourceType.connection:
                return [item.parent.info.source];
            case SourceType.meter:
                return [item.parent.parent.info.source];
        }
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        sendCommand("com.flowmaps.api.user.SaveDashboard", command, () => {
            AppContext.userProfile.dashboards.push(savedDashboard);
            AppContext.navigateToUrl("/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard)));
        });
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppContext.navigateToUrl("/");
            }
        });
    }

    updateAndNavigateToDashboard() {
        this.ngZone.run(() => {
            this.dashboard.info.sources = this.dataProvider.sourceProvider.selectedSources;
            return this.router.navigateByUrl("/dashboard/" + DashboardContext.dashboardToBase64(this.dashboard));
        });
    }

    downloadMonthlyData = () => downloadBulkMonthData(this.dataProvider.sourceProvider, this.dataProvider.info.timeRange);
}