<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end g-3">
                <div class="col">
                    <h6 class="header-pretitle">Building</h6>
                    <h1 class="notranslate header-title">{{ location?.info ? location?.info?.name + ' - ' + refDataUtils.addressFormatter(location.info.address) : "" }}</h1>
                </div>
                <div class="col-auto">
                    <app-confirmation-toggle *ngIf="dashboard?.dashboardId">
                        <div class="btn btn-outline-danger">Delete</div>
                        <div class="modal-content">
                            <div class="modal-card card">
                                <div class="card-header">
                                    <h4 class="card-header-title">Delete dashboard?</h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    ></button>
                                </div>
                                <div class="card-body">
                                    <p>Are you sure you want to delete this dashboard?</p>
                                </div>
                                <div class="card-footer d-flex justify-content-between">
                                    <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel
                                    </a>
                                    <button type="button" class="btn btn-danger"
                                            (click)="deleteDashboard(dashboard.dashboardId)" data-bs-dismiss="modal">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </app-confirmation-toggle>
                </div>
                <div class="col-auto">
                    <div class="btn btn-primary" (click)="openModal(saveDashboardModal)">Save
                        dashboard
                    </div>

                    <div class="btn btn-outline-primary rounded-top ms-4 d-none">
                        <i class="bi bi-pencil-fill pe-sm-2"></i>
                        <span class="d-none d-sm-inline-block">Customize</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div (change)="updateAndNavigateToDashboard()">
    <div class="container-fluid sticky-md-top bg-body" style="z-index: 1050">
        <div class="row gx-3 justify-content-between py-4">
            <div class="col-12 col-md-6 ps-3 pe-md-0">
                <div class="row mb-3">
                    <div class="col">
                        <app-search [minCharacters]="3" [(ngModel)]="location" (ngModelChange)="updateLocation($event)"
                                    [inputFormatter]="refDataUtils.locationsFormatter"
                                    [resultFormatter]="refDataUtils.locationsFormatter"
                                    [searchFunction]="refDataUtils.getAllLocations"
                                    placeholder="Select a location"></app-search>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 d-flex justify-content-end">
                <app-data-provider-date-range [dataProvider]="dataProvider"></app-data-provider-date-range>
            </div>
        </div>
    </div>
    <ng-container *ngIf="location">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <app-energy-summary-widget [dataProvider]="dataProvider"></app-energy-summary-widget>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-grow-1">
                    <div class="card flex-grow-1"
                         style="background-image: url('assets/images/building-background-v3.jpg');
                         background-repeat: no-repeat; background-size: contain; background-position: center; min-height: 350px">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex flex-grow-1">
                    <div class="card flex-grow-1">
                        <div class="card-body">
                            <app-location-energy-label-info [location]="location"></app-location-energy-label-info>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 d-flex flex-grow-1">
                    <div class="card flex-grow-1">
                        <div class="card-body">
                            <app-location-meters [location]="location"></app-location-meters>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex flex-grow-1">
                    <div class="card flex-grow-1">
                        <div class="card-header">
                            <h4 class="card-header-title">
                                <img src="assets/images/icons/environmentalism.png" width="20px" class="me-2">
                                Energy compass
                            </h4>
                        </div>
                        <div class="card-body d-flex flex-grow-1">
                            <app-location-energy-compass [dataProvider]="dataProvider" [location]="location"
                                                         class="d-flex flex-grow-1"></app-location-energy-compass>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-co2-chart class="card flex-grow-1"
                                   [data]="{dataProvider: contractMeasurementsDataProvider}"></app-co2-chart>
                </div>
                <div class="col-12 col-md-6">
                    <app-costs-chart class="card d-flex align-self-stretch flex-grow-1"
                                     [data]="{dataProvider: contractMeasurementsDataProvider}"></app-costs-chart>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-stretch">
                    <app-meter-performance-chart class="d-flex align-self-stretch flex-grow-1"
                                                 [data]="{dataProvider: dataProvider, stacked: true}">
                    </app-meter-performance-chart>
                </div>
                <div class="col-12 col-md-6">
                    <app-electricity-chart class="chart" [data]="{dataProvider: dataProvider, stacked: true}">
                    </app-electricity-chart>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-gas-chart class="chart" [data]="{dataProvider: dataProvider}"></app-gas-chart>
                </div>
                <div class="col-12 col-md-6">
                    <app-water-chart class="chart" [data]="{dataProvider:dataProvider}"></app-water-chart>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-day-of-week-chart class="d-flex align-self-stretch flex-grow-1"
                                           [data]="{dataProvider: dayOfWeekDataProvider, stacked: true}"
                                           [allDaysByDefault]="true"></app-day-of-week-chart>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="modal fade" id="saveDashboardModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-card card" data-list='{"valueNames": ["name"]}'>
                <div class="card-header">
                    <h4 class="card-header-title" id="exampleModalCenterTitle">Save dashboard</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="card-body">

                    <!-- List group -->
                    <div class="mb-3">
                        <label for="dashboardName" class="form-label">Name</label>
                        <input type="text" [(ngModel)]="dataProvider.info.name" name="dashboardName"
                               class="form-control" id="dashboardName">
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="saveDashboard()" data-bs-dismiss="modal"
                    >Save
                    </button>

                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #saveDashboardModal>
    <app-form (submit)="saveDashboard()">
        <div class="modal-content">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-header-title">Save dashboard</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="dashboardName" class="form-label">Name</label>
                        <input type="text" [(ngModel)]="dataProvider.info.name" name="dashboardName"
                               class="form-control">
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between">
                        <a class="btn btn-secondary" data-bs-dismiss="modal">Cancel</a>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </app-form>
</ng-template>