<div #container>
    <div class="card-header">
        <h4 class="card-header-title">
            <img src="assets/images/icons/co2.png" width="20px" class="me-2">
            <span class="ml-2 notranslate">CO<sub>2</sub></span>
        </h4>
        <app-chart-download-button [element]="container" [tableElement]="tableElement" [chartProvider]="_dataOptions.dataProvider"></app-chart-download-button>
    </div>
    <div class="card-body pt-0">
        <app-doughnut-chart class="chart chart-appended h-100 d-flex align-items-center justify-content-center"
                            [doughnutText]="(totalCo2 | number) + ' tn'"
                            [measurementsProvider]="_dataOptions.dataProvider"
                            [dataProvider]="chartDataProvider" [height]="300"
                            [animationDuration]="showInReport ? 0 : 1000"
                            [withCircumference]="true"></app-doughnut-chart>
    </div>
</div>
