import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SustainabilitySource} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../refdata-utils";
import {AbstractValueAccessorComponent} from "../../../common/component/value-accessor.component";

@Component({
  selector: 'app-sustainability-source-select',
  templateUrl: './sustainability-source-select.component.html',
  styleUrls: ['./sustainability-source-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SustainabilitySourceSelectComponent),
      multi: true
    }
  ]
})
export class SustainabilitySourceSelectComponent extends AbstractValueAccessorComponent<SustainabilitySource>  {
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() connectionType?: string;

  private _value: SustainabilitySource;

  getSources = () => RefdataUtils.getSustainabilitySources(this.connectionType);

  get value(): SustainabilitySource {
    return this._value;
  }

  set value(value: SustainabilitySource) {
    this.writeValue(value);
  }

  writeValue(value: SustainabilitySource): void {
    this._value = value;
    this.onModelChange();
  }

  providerFormatter = (value: SustainabilitySource) => value ? `${value.name}` : null;
}
