import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {checkValidity} from "../../../../common/utils";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {DateFieldRange} from "../../../../common/date/date-range/date-field-range";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {downloadRawConnectionData} from "../connections-list/connections-list.component";

@Component({
  selector: 'app-connection-selection',
  templateUrl: './connection-selection.component.html',
  styleUrls: ['./connection-selection.component.scss']
})
export class ConnectionSelectionComponent {
    dashboardContext = DashboardContext;
    appContext = AppContext;

    @Input() comparator: ComparatorChain;
    @Input() selectedItems: any[] = [];
    @Input() sheetTemplate: any;
    @Input() csvMapper: (csv: string) => any[];
    @Input() onDownloaded: () => void;
    @Input() cancelMethod: () => void;

    defaultDownloadRange = DashboardContext.defaultRange;

    resolution: TimeResolution;
    timeRange: DateFieldRange = this.defaultDownloadRange();
    @ViewChild("downloadDataBar") downloadDataBar: ElementRef;

    downloadData = () => {
        if (checkValidity(this.downloadDataBar.nativeElement)) {
            if (this.selectedItems.length === 0) {
                AppContext.registerError("Please select at least 1 record");
                return;
            }
            downloadRawConnectionData(this.timeRange, this.resolution, this.selectedItems, this.csvMapper, this.comparator, this.onDownloaded);
        }
    }
}