import {ChartDataPerMeasurement, MeasurementsDataProvider} from "./measurements-data-provider";
import {
    ContractMeasurementResult,
    GetContractMeasurements,
    Organisation,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {Observable} from "rxjs";
import {sendQuery} from "../flux/flux-utils";
import {DashboardContext} from "../views/dashboard/dashboard.context";
import {CompletenessInfo} from "../app-context";
import {lodash} from "../common/utils";

export class ContractMeasurementsDataProvider extends MeasurementsDataProvider<ContractMeasurementResult> {
    hasData: () => boolean = () => !lodash.isEmpty(this.dashboardData?.currentData.contractMeasurements);

    getDataForRange(timeRange: TimeRange): Observable<ContractMeasurementResult> {
        return sendQuery("com.flowmaps.api.measurements.GetContractMeasurements", <GetContractMeasurements>{
            timeRange: timeRange,
            sourceIds: this.sourceProvider.sourceSelectionAfterCleanup(),
            resolution: this.info.resolution,
            unrounded: ![TimeResolution.year, TimeResolution.month].includes(this.info.resolution)
        });
    }

    createChartData(data: ContractMeasurementResult, completeness: CompletenessInfo[], organisations: Organisation[], dateRange: TimeRange, stack: string = DashboardContext.stacks.currentPeriod, comparedYear?: number): ChartDataPerMeasurement {
        if (stack === DashboardContext.stacks.currentPeriod) {
            this.dashboardData.labels = MeasurementsDataProvider.getSlots(dateRange, this.info.resolution).map(s => s.label);
        }
        return {
            contractMeasurements: data
        }
    }

    copy(): ContractMeasurementsDataProvider {
        return new ContractMeasurementsDataProvider(this.chartUtils, this.info, this.selectedSources)
    }
}