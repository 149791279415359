<div class="dropdown">
    <button type="button" class="btn" role="button" data-bs-toggle="dropdown"
            data-bs-auto-close="outside" aria-expanded="false">
        <i class="bi bi-download"></i>
    </button>
    <div class="dropdown-menu" style="z-index: 1050;">
        <button class="dropdown-item" (click)="downloadImage()">
            <i class="bi bi-image"></i>
            <span class="ms-2">Download as image</span>
        </button>
        <button class="dropdown-item" (click)="downloadTable()" *ngIf="tableElement">
            <i class="bi bi-table"></i>
            <span class="ms-2">Download table</span>
        </button>
        <ng-container *ngIf="rawDataExportSupported">
            <ng-container *ngIf="!this.selectedConnections?.length else normalView">
                <div class="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="top" title="No connections found within selection">
                    <button class="dropdown-item disabled">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="mx-2">Download raw data</span>
                        <i class="bi bi-exclamation-triangle"></i>
                    </button>
                </div>
            </ng-container>
            <ng-template #normalView>
                <ng-container *ngIf="belowRawDataThreshold() else toManyConnectionsSelected">
                    <button class="dropdown-item" (click)="downloadRawData('minute')">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="ms-2">Download raw data</span>
                    </button>
                    <button class="dropdown-item" (click)="downloadRawData('hour')">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="ms-2">Download hourly data</span>
                    </button>
                    <button class="dropdown-item" (click)="downloadRawData('day')">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="ms-2">Download daily data</span>
                    </button>
                    <button *ngIf="duration.asMonths() >= 1" class="dropdown-item" (click)="downloadRawData('month')">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="ms-2">Download monthly data</span>
                    </button>
                    <button *ngIf="duration.asYears() >= 1" class="dropdown-item" (click)="downloadRawData('year')">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="ms-2">Download yearly data</span>
                    </button>
                </ng-container>
            </ng-template>
            <ng-template #toManyConnectionsSelected>
                <div class="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="top" [title]="toManyConnectionsTooltip">
                    <button class="dropdown-item disabled">
                        <i class="bi bi-file-earmark-excel"></i>
                        <span class="mx-2">Download raw data</span>
                        <i class="bi bi-exclamation-triangle"></i>
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
