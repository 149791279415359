<div class="row">
    <div class="col-12 col-md-6" *ngIf="info.marketSegment === 'KV'">
        <app-form-field label="Connection value">
            <app-connection-value-select [(ngModel)]="info.connectionValue" class="notranslate"
                                         [type]="info.connectionType"></app-connection-value-select>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="GOS code">
            <input [(ngModel)]="info.gosCode" type="text" class="form-control">
        </app-form-field>
    </div>
</div>