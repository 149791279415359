<div class="row">
    <div class="col-12 col-md-6" *ngIf="info.marketSegment === 'KV'">
        <app-form-field label="Connection value">
            <app-connection-value-select [(ngModel)]="info.connectionValue" class="notranslate"
                                         [type]="info.connectionType"></app-connection-value-select>
        </app-form-field>
    </div>
    <div class="col-12 col-md-6">
        <app-form-field label="Residential function">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value=""
                       [(ngModel)]="info.withResidentialFunction">
            </div>
        </app-form-field>
    </div>
</div>